@import '../../../Design/tokens';
@import '../../../Design/classes';

.covered-entity-modal-trigger {
  @include body-reg-01;
  color: $blue-darker;
  cursor: pointer;

  border: none;
  background: transparent;

  &.regular {
    @include body-reg-02;
    color: $blue-darker;
    padding: 0;
  }
}

.covered-entity-modal {
  padding: 40px 32px;
  max-height: 662px;

  .table-heading {
    @include body-reg-01;
    color: $primary-medium;
    border-bottom: 1px solid $gray-lighter-border;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 16px;
  }

  .other-entities {
    margin-top: 16px;
  }

  .entity-count {
    @include body-semi-02;
  }

  .covered-entity {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 0;
  }

  .body {
    max-height: 480px;
    overflow-y: auto;
    padding: 0 8px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 28px;
  }
}
