@import '../../Design/tokens';

.checkicon-outline {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: $evergreen-extralight;
  align-self: center;
  margin-bottom: 20px;
}

.checkicon {
  color: $evergreen-dark;
  margin: 6px 4px !important; // override semantic icon margins
}

.error-icon-outline {
  border-radius: 50%;
  background: $red-light;
  align-self: center;
  margin-bottom: 20px;

  &.big {
    height: 40px;
    width: 40px;
  }

  &.huge {
    height: 70px;
    width: 70px;
  }
}

.error-icon {
  color: $red-darker;

  &.big {
    margin: 6px 4px;
  }

  &.huge {
    margin: 6px 2px;
  }
}
