@import '../../Design/tokens';

.data-table-wrapper {
  width: 100%;
  overflow-y: hidden;
  overflow-x: scroll !important;
  display: block;

  .data-table {
    white-space: nowrap !important;
    border: none !important;
    width: 100%;

    .text-align-right {
      text-align: right;
    }

    thead {
      background: $primary-extra-light;

      th {
        color: black !important;
        border-bottom: 1px solid rgba(34, 36, 38, 0.1) !important;
      }
    }

    tr {
      td {
        height: 43px;
      }
    }

    td.sticky-column {
      background: white;
    }

    .sticky-column {
      position: -webkit-sticky;
      position: sticky;
      left: 0px;
      z-index: 1;
      box-shadow: 2.5px 0px 2px -1px #d1d0d0;

      &.spaced {
        left: 39px;
        transition: none; //To disable hover transition delay
      }

      &.hidden {
        box-shadow: none;
        transition: none; //To disable hover transition delay
      }

      &.no-pointer {
        cursor: initial;
      }
    }

    .selectable-row {
      cursor: pointer;

      .ui.checkbox input:checked~label:after {
        color: black;
      }

      &.active {
        background: $cobalt-extralight !important;

        td.sticky-column {
          background: $cobalt-extralight !important;
        }

        td.sticky-column:not(.hidden) {
          box-shadow: 2.5px 0px 2px -1px #d1d0d0 !important;
        }
      }
    }

    .selectable-row:hover:not(.active) {
      background: #F2F2F2 !important;

      td.sticky-column {
        background: #F2F2F2 !important;
      }
    }

    .ui.table {

      tr.active,
      td.active {
        box-shadow: none;
      }
    }
  }
}

.data-table-with-action-container {
  padding-right: 150px;
  width: 100%;
  position: relative;

  &.empty {
    min-height: auto;
  }
}

.action-cell {
  background: $bg-gray;

  .transaction-select-container {
    margin: 0 8px;

    .status-text {
      @include body-semi-01;
    }

    .transactions-select-action {
      width: 100%;
      letter-spacing: 0.25px;

      &:hover {
        .action-text {
          color: $primary-medium;
        }
      }

      .dropdown.icon {
        position: absolute;
        top: 4px;
        right: 0;
      }

      .action-text {
        @include body-semi-01;
        color: $primary-light;
      }
    }
  }
}

.action-table-wrapper {
  width: 20%;

  .action-table {
    top: 0;
    width: 150px !important;
    border: none !important;
    box-shadow: -4px 8px 10px rgba(0, 0, 0, .1);

    thead {
      background: $primary-extra-light;

      th {
        height: 45px !important;
        color: black !important;
      }
    }

    tr td {
      height: 43px;
      padding: 0 !important;
      background: $primary-extra-light;
    }

    .ui.selection.dropdown {
      min-width: 0 !important;
      width: 100%;
      border: none;

      .menu {
        border: none;

        .item {
          font-size: $font-size-1;

          &.action-inline-menu-text {
            font-weight: 600;
            color: $gray-darker;
            line-height: $font-height-1;
            display: flex;
            align-items: center;
            letter-spacing: 0.25px;
            padding: 10px 16px !important;
          }
        }
      }
    }

    .action-menu {
      width: 100%;
    }
  }

  .absolute-right {
    position: absolute;
    right: 0;
  }
}

.basic-table-wrapper {
  background: $white;
  display: flex;
  flex-direction: column;
  width: 100%;

  .basic-table {
    width: 100%;
    border: none !important;
    border-collapse: collapse !important;
    font-size: $font-size-1;
    line-height: $font-height-1;
    letter-spacing: 0.25px;
    color: $gray-darker;
    margin-top: 0;

    & .selectable-row {
      cursor: pointer;
    }
    thead {
      border-bottom: 1px solid $gray-lighter-border !important;

      th {
        padding: 16px 0.785714em 16px 0.785714em;
        background: $white !important;
        color: $primary;
        font-weight: 600;
        white-space: nowrap;
      }
    }

    thead.grey {

      th {
        background: $bg-gray !important;
      }
    }


    tr {
      td {
        border: none;
        padding: $spacing-xs $spacing-s;
      }


    }
  }
}

.header-cell {
  color: black !important;
  border-collapse: collapse;

  &.pharmacyNPI-header {
    display: flex;
    flex-direction: row;
    min-width: 118px;

    .sort-icon-container {
      padding-top: 6px;
    }
  }
  .pharmacy-facility-NPI {
    white-space: pre-line ;
  }
}

.sort-icon-container {
  display: inline-flex;
  flex-direction: column;
  height: 16px;
  cursor: pointer;

  .sort-caret-up,
  .sort-caret-down {
    margin-right: 0px;
    text-align: right;
    position: relative;
    font-size: 16px !important;
    color: #bdbdbd;
  }

  .sort-caret-up {
    z-index: 0;
    bottom: 3px;
  }

  .sort-caret-down {
    z-index: 0;
    bottom: 10px;
  }

  .sort-caret-up:hover:not(.disabled),
  .sort-caret-down:hover:not(.disabled) {
    color: $blue-hover !important;
  }

  .disabled {
    cursor: default;
  }
}

.sub-menu-container {
  width: 100%;
  background: $primary-extra-light;
  height: 60px;
  justify-content: space-between;

  &.hidden {
    visibility: hidden;
  }

  &.display-none {
    display: none;
  }

  .sub-menu-right-side-components {
    flex: 1;
    justify-content: flex-end;
  }

  .sub-menu-left-side-components {
    align-items: center;
    display: flex;
    margin-left: 14px;
  }

  .sub-menu-pagination-container {
    margin-top: 24px;
    margin-right: 25px;
    position: relative;

    i:hover {
      color: #3c61e3;
    }

    .pagination-controls {
      color: $primary-medium;
      margin-right: 15px;
    }
  }
}
