@import './../Design/tokens';

.contract-filter {
  padding-bottom: 8px;

  .contract-filter-title {
    padding-bottom: 8px;
  }

  .contract-filter-dropdown {
    display: flex !important;
    flex-direction: row !important;
    background: $white !important;

    i.chevron {
      margin-top: auto;
      margin-bottom: auto;
    }

    .all-contract-option {
      padding-top: 10px;
    }

    &.full-width {
      width: 100%;

      .text {
        width: 100%;
        height: 45px;
        overflow: hidden;
      }
    }

    &.ui.scrolling.dropdown {
      padding-left: 16px;
      padding-right: 16px;

      .dropdown.icon {
        top: 20px;
        font-size: 16px;
      }
    }

    &.ui.scrolling.dropdown .menu {
      width: calc(100% + 2px) !important;

      .selected.item {
        background: #EDEFF1;
        color: inherit;
      }

      .item:hover {
        background: #EDEFF1;
        color: inherit;
      }

      .item {
        border-top: 1px solid $gray-lighter-border;
      }

      @media only screen and (min-width: 992px) {
        max-height: 30rem;
      }
    }
  }
}

.contract-row {
  
  .contract-bucket-item-container {

    .contract-name {
      flex: 2 1;
    }

    .contract-bucket-item {
      flex: 1 1;
      border-left: 1px solid $gray-lighter-border;
      padding-left: 1.5rem;

      &.suspense {
        color: $golden-dark;
      }

      &.empty {
        color: $primary-medium;
      }

      .contract-bucket-item-value {
        padding-bottom: 8px;
      }
    }
  }

  .contract-item-container {
    flex-wrap: wrap;
    padding-top: 24px;

    .contract-item {
      padding-right: 12px;
      margin-right: 12px;
      white-space: nowrap;
    }

    .contract-item-value {
      padding-bottom: 8px;
    }

    .contract-item-text {
      color: $primary-medium;
    }
  }


  .contract-link {
    border: 1px solid #BDBDBD;
    white-space: nowrap;
    border-radius: 1px;
    padding: 10px 16px;

    i.icon {
      color: #BDBDBD;
      margin-left: 10px;
    }

    &:hover {
      border: 1px solid $gray-darker;
      color: $gray-darker;

      i.icon {
        color: $gray-darker;
      }
    }
  }

}