html, body {
  height: 100% !important;
  overflow: hidden;
}

#root{
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.scrollable-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 2rem 2rem;
  overflow-y: auto;
  height: 100%;
}
.app-content{
  position: relative;
}

/* generic classe to use througout the app*/
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-grow {
  flex-grow: 1;
}
.flex-noshrink {
  flex-shrink: 0;
}
.flex-center {
  align-items: center;
}
.flex-end {
  align-items: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-space-between {
  justify-content: space-between;
}
.justify-flex-end {
  justify-content: flex-end;
}
.justify-space-evenly{
  justify-content: space-evenly;
}
.align-center{
  align-items: center;
}
.scroll-hidden {
  overflow: hidden;
}
.v-scroll-auto {
  overflow-y: auto;
}
.relative {
  position: relative;
}
.cursor-pointer {
  cursor: pointer;
}

.beta-regular {
  font-size: 20px;
  line-height: 25px;
}

.epsilon-regular {
  font-size: 14px;
  line-height: 18px;
}

.zeta-regular {
  font-size: 12px;
  line-height: 16px;
}

.eta-regular {
  font-size: 12px;
  line-height: 16px;
}

.gusto {
  font-size: 28px;
  line-height: 35px;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

#launcher {
  display: none;
}
