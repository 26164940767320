@import '../../../Design/classes';
@import '../../../Design/tokens';

.mfr-summary-card {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 13px 18px 14px 24px;
  justify-content: space-between;

  .mfr-summary-card-left-container {
    display: flex;
    flex-direction: column;

    .mfr-summary-card-header {
      @include body-reg-03();
      color: $cobalt-dark;
      padding-bottom: 5px;
      text-align: left;
    }

    .mfr-summary-card-items {
      align-items: center;
      display: flex;
      flex-direction: row;
    }
  }

  .mfr-summary-card-right-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    
    .mfr-summary-card-button {
      font-size: $font-size-1 !important;
      height: 32px;
      margin-right: 18px;

      svg {
        color: $cobalt-dark;
        margin-left: 10px;
      }

      &:active {
        background: $gray-light !important;
      }

      &:focus {
        border: 1px solid $gray-medium;
        text-decoration: none;
      }
    }
  }

  &.sub-row {
    background: $bg-gray;
    border: none;
    border-top: 1px solid $gray-light;
    height: 48px;
    padding: 8px 24px;
    width: 100%;

    &:hover {
      background: $gray-lighter;
      cursor: pointer;

      .sub-row-view {
        visibility: initial;
      }
    }

    &:active {
      background: $gray-light;
    }

    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .sub-row-view {
      @include body-reg-01;
      align-items: center;
      display: flex;
      font-weight: 600;
      visibility: hidden;

      svg {
        color: $cobalt-dark;
        margin-left: 5px;
      }
    }
  }
}
