@import '../../Design/tokens';

.password-validation-container {
  display: flex;
  flex-direction: column;

  .password-validation {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: $font-size-1;
    line-height: $font-height-1;
    letter-spacing: 0.25px;
    width: 275px;

    .icon {
      font-size: 16px;
      margin-right: $spacing-s;

      &.green-icon {
        color: $evergreen-medium;
      }
    }

    div {
      flex: 1;
    }
  }

  .password-validation:not(:last-child) {
    margin-bottom: $spacing-s;
  }



}