@import './tokens';

// do not edit

@mixin body-reg-01() {
  font-family: $font-family-sans-reg;
  font-size: $font-size-1;
  letter-spacing: 0.25px;
  line-height: $font-height-1;
  font-weight: 400;
  color: $primary;
}

@mixin body-reg-02() {
  font-family: $font-family-sans-reg;
  font-size: $font-size-2;
  letter-spacing: 0.25px;
  line-height: $font-height-2;
  font-weight: 400;
  color: $primary;
}

@mixin body-reg-03() {
  font-family: $font-family-sans-reg;
  font-size: $font-size-3;
  letter-spacing: 0.25px;
  line-height: $font-height-3;
  font-weight: 400;
  color: $primary;
}

@mixin body-semi-01() {
  font-family: $font-family-sans-semi;
  font-size: $font-size-1;
  letter-spacing: 0.25px;
  line-height: $font-height-1;
  font-weight: 500;
  color: $primary;
}

@mixin body-semi-02() {
  font-family: $font-family-sans-semi;
  font-size: $font-size-2;
  letter-spacing: 0.25px;
  line-height: $font-height-2;
  font-weight: 500;
  color: $primary;
}

@mixin body-semi-03() {
  font-family: $font-family-sans-semi;
  font-size: $font-size-3;
  letter-spacing: 0.25px;
  line-height: $font-height-3;
  font-weight: 500;
  color: $primary;
}

@mixin header-sans-semi-01() {
  font-family: $font-family-sans-semi;
  font-size: $font-size-4;
  letter-spacing: 0.25px;
  line-height: $font-height-4;
  font-weight: 600;
  color: $primary;
}

@mixin header-sans-reg-01() {
  font-family: $font-family-sans-reg;
  font-size: $font-size-4;
  letter-spacing: 0.25px;
  line-height: $font-height-4;
  font-weight: 400;
  color: $primary;
}

@mixin display-serif-01() {
  font-family: $font-family-serif-semi;
  font-size: $font-size-5;
  letter-spacing: 0px;
  line-height: $font-height-5;
  font-weight: 600;
  color: $primary;
}

@mixin display-serif-02() {
  font-family: $font-family-serif-semi;
  font-size: $font-size-6;
  letter-spacing: 0px;
  line-height: $font-height-6;
  font-weight: 600;
  color: $primary;
}

@mixin display-sans-01() {
  font-family: $font-family-sans-semi;
  font-size: $font-size-5;
  letter-spacing: 0px;
  line-height: $font-height-5;
  font-weight: 600;
  color: $primary;
}