@import "../../Design/tokens";

.associated-pharmacies-tab {
  background: $white;
  height: 100%;

  &.gray {
    background: $gray-medium-light;
  }

  &.no-content {
    overflow: hidden;
  }

  .padded-tab {
    padding: 0 20px;
  }

  .associated-pharmacies-tab-paging-menu {
    background: $white;
    border-bottom: 1px solid $gray-lighter-border;

    &.gray {
      background: $gray-lighter;
      border-bottom: none;
    }
  }

  .associated-pharmacies-tab-loader {
    position: fixed !important;
    top: 400px !important;
    left: 54% !important;
  }
}

.associated-pharmacies-footer {
  z-index: 1;
  height: 84px;
  display: flex;
  justify-content: flex-end;
  padding: 24px;
  box-shadow: $box-shadow-reg;
  position: relative;

  .footer-button {
    margin-right: 15px;
    border-color: $primary !important;

    &.add,
    &.add:hover {
      color: $blue-darker !important;

      &.disabled {
        border-color: $gray-medium !important;
        color: $gray-medium !important;
      }
    }

    &.remove,
    &.remove:hover {
      color: $red-darker !important;

      &.active {
        background: $red-lighter !important;
      }
    }

    svg {
      margin-left: 11px;
    }
  }

  .footer-dimmer {
    position: absolute;
  }
}

.associated-pharmacies-table {
  tbody {
    font-size: 12px !important;
  }

  // used id to override existing table !important
  #edit-header-cell {
    color: $blue-darker !important;
    padding-bottom: 7px;
    padding-left: 16px;
    padding-right: 16px;
    cursor: pointer;
  }

  #remove-header-cell {
    color: $red-darker !important;
    padding-bottom: 7px;
    padding-left: 20px;
    padding-right: 21px;
  }

  .remove-cell {
    align-items: center;
    display: flex;
    background: $red-medium;
    cursor: pointer;
    height: 48px !important;
    justify-content: center;

    svg {
      color: $white;
    }

    &:hover {
      background: $red-dark;
    }
  }

  .edit-cell {
    padding: 6px !important;
  }

  .pharmacy-row {
    height: 48px;
  }

  .pharmacy-row-edit-mode {
    height: 48px;

    &:hover {
      background: $gray-lighter;

      .edit-cell-button {
        display: flex !important;
        height: 32px !important;
      }
    }
  }

  .edit-cell-button {
    // important overrides app-button styling
    background: $white !important;
    color: $blue-darker !important;
    display: none !important;
    font-size: 12px !important;
    padding: 8px 16px !important;

    &:hover {
      background-color: #EBEBEB !important;
    }

    svg {
      margin-left: 5px;
    }
  }
}

.associated-pharmacies-table-container {
  padding-right: 185px;
  width: 100%;
  position: relative !important;
}

.associated-pharmacies-action-table {
  .action-table-wrapper .action-table {
    top: 0;
    width: 185px !important;
  
    .tooltip {
      color: $gray-medium;
      position: relative;
      margin-left: 5px;
      margin-top: 0.3px;
    }
  
    thead th {
      height: 38px !important;
      padding-top: 14px;
    }
  
    tr td {
      height: 48px;
      padding: 0 12px 0 12px !important;
    }
  
    .checkbox {
      pointer-events: auto;
  
      &.enabled {
        left: 15px;
  
        input:checked~label:after {
          background: $gray-darker;
          color: $white !important;
          border: 1px solid $gray-medium-light;
        }
      }
  
      &.disabled {
        left: 15px;
        pointer-events: none;
  
        input:checked~label:after {
          background: $gray-darker;
          color: $white !important;
          border: 1px solid $gray-medium-light;
        }
      }
    }
  }
}
