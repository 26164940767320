@import './../Design/tokens';

.recipient-request-center {

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 !important;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  &.y-scroll-hidden {
    overflow-y: hidden;
  }

  .recipient-request-center-circles-container {
    width: 100%;
    height: 143px;
    background: $cobalt-extralight;

  }

  .recipient-request-center-info-circle3 {
    width: 289px;
    height: 163px;
    background: #EBEFFF;
    mix-blend-mode: normal;
    left: 77%;
    top: -90px;
    border-radius: 0;
    border-top-right-radius: 300px;
    border-top-left-radius: 300px;
    position: relative;
  }

  .recipient-request-center-info-circle4 {
    width: 162px;
    height: 74px;
    background: $primary-extra-light;
    mix-blend-mode: multiply;
    left: 72%;
    top: -164px;
    border-radius: 0;
    position: relative;
    border-top-right-radius: 800px;
    border-top-left-radius: 800px;
  }

  .recipient-request-center-header-div {
    margin-left: 20px;
    border-bottom: 1px solid $gray-lighter-border;
    position: relative;
    width: 95%;
  }

  .tab-controller.recipient-request-center-tabs {
    position: relative;
    bottom: 204px;

    .recipient-transactions-tab {
      background: $white;

      .padded-tab {
        padding: 24px 24px 0 24px;
      }

      .ui.dimmer {
        margin-top: 24px; //avoid covering 'submit new' button. todo: not this
        min-height: 100vh;

        &.no-margin {
          margin-top: 0;
        }
      }

      .recipient-transactions-banner {
        height: 66px;
        background: rgba(254, 229, 208, 0.3);
        padding: 24px;


        .recipient-transactions-banner-text {
          font-family: Averta;
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-2;
          line-height: $font-height-2;
          letter-spacing: 0.25px;
          color: $gray-darker;
        }
      }

      .recipient-transactions-table-header {
        z-index: 2; // used to overlay the table on IE11
      }
    }
  }

  .recipient-request-center-loader {
    position: fixed !important;
    top: 400px !important;
    left: 54% !important;
  }

}

.recipient-transactions-table {

  .recipient-date-value {
    font-family: Averta;
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-1;
    color: $primary-medium;
    letter-spacing: 0.25px;
  }

  .recipient-submitted-label {
    color: $cobalt-dark;
    white-space: nowrap;
    padding: 4px 9px;
    border-radius: 3px;
    background: $cobalt-extralight;
    border: 1px solid $cobalt-light;
  }

  .recipient-paid-label {
    color: $evergreen-dark;
    white-space: nowrap;
    background: $evergreen-extralight;
    padding: 4px 9px;
    border-radius: 3px;
    border: 1px solid $evergreen-light;
  }

  .recipient-processing-label {
    color: $golden-dark;
    white-space: nowrap;
    background: $golden-extralight;
    padding: 4px 9px;
    border-radius: 3px;
    border: 1px solid $golden-medium;
  }

  .recipient-failed-label {
    color: $red-darker;
    white-space: nowrap;
    background: $red-light;
    padding: 4px 9px;
    border-radius: 3px;
    border: 1px solid $red-medium;
  }

  .recipient-paid-adjust-label {
    white-space: nowrap;
    padding: 4px 9px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border: 1px solid $evergreen-light;
    background: $evergreen-extralight;
    color: $evergreen-dark;
  }

  .recipient-adjust-paid-label {
    white-space: nowrap;
    padding: 4px 9px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border: 1px solid $evergreen-dark;
    background: $evergreen-dark;
    color: $white;
  }

  .recipient-in-review {
    color: $white;
    white-space: nowrap;
    background: $golden-dark;
    padding: 4px 9px;
    border-radius: 3px;
    border: 1px solid $golden-dark;
  }

  .recipient-reversal-processing {
    color: $primary-medium;
    background: $primary-extra-light;
    border: 1px solid $primary-light;
    border-radius: 3px;
    padding: 4px 9px;
  }

  .recipient-reversed-transaction {
    color: $white;
    background: $primary-medium;
    border: 1px solid $primary-medium;
    border-radius: 3px;
    padding: 4px 9px;
  }

  .recipient-reversed-failed-transaction {
    color: $primary-light;
    background: $white;
    border: 1px solid $primary-light;
    border-radius: 3px;
    padding: 4px 9px;
  }

  .recipient-amt-value {
    text-align: right !important;
  }

  .recipient-amt-value-override {
    position: relative;
    text-align: right !important;

    &:before {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 10px 0px 0px 10px;
      top: 0px;
      right: 0px;
      border-color: #9c9c9c transparent transparent transparent;
    }
  }

  .recipient-pending-reversal-indicator {
    display: inline-block;
    position: relative;

    .redo-icon {
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: 3px;
      right: -10px;
    }
  }
}


.reversal-modal {
 
  .download-icon {
    margin-top: auto;
    margin-left: 5px;
    font-size: 16px;
    margin-bottom: 3px;
    color: #c7bcbc !important
  }

  .modal-header-row {
    display: flex;
    margin-top: 25px;
    flex-direction: row;
    justify-content: space-between;
  }

  .modal-subheader-row {
    margin-top: 14px;
  }

  .modal-summary-container {

    .modal-summary-row {
      display: flex;
      width: 93%;
      border-bottom: 1px $gray-lighter-border solid;
      margin-left: auto;
      margin-right: auto;
      flex-direction: row;
      justify-content: space-evenly;

      &:last-child {
        border-bottom: none !important;
      }
    }
  }

  .modal-product-row {
    margin-top: 30px;
    display: flex;
    width: 93%;
    margin-left: auto;
    margin-right: auto;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .modal-header {
    margin-left: 25px;
  }

  .close-icon-container {
    margin-right: 25px;

    &:hover {
      cursor: pointer;
    }
  }

  .modal-sub-header {
    margin-left: 25px;
    margin-right: 25px;
  }

  .reverse-info {
    width: 20%;
    text-align: left;
    color: $primary-medium;
    min-height: 45px;
    padding: 8px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .reverse-info-sub {
    width: 20%;
    font-weight: normal;
    text-align: left;
    min-height: 45px;
    padding: 8px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .modal-button-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 25px;
    margin-bottom: 20px;

    .btn-header {
      margin-left: 25px;
      font-weight: bold;
      margin-top: auto;
      font-size: $font-size-2;
      line-height: 18px;
      margin-right: 7px;
    }

    .cancel-btn {
      background: transparent;
      border: $primary-medium 1px solid;
      margin-right: 15px;

      &:hover {
        color: black;
      }
    }

    .reverse-btn {
      margin-right: 25px;
    }
  }
}

.selected-transactions-message {
  height: 60px;
  background: $gray-darker;

  .selected-transactions-message-header {
    font-family: Averta;
    line-height: 18px;
    letter-spacing: 0.25px;
    font-size: $font-size-2;
    margin-top: 8px;
    color: white;
    float: left;
    width: 300px;
    padding-left: 20px;

    span {
      line-height: 45px;
    }
  }

  .selected-transactions-message-btns-container {
    width: 210px;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    color: white;
    float: right;
    margin-top: 19px;
    align-items: center;

    .selected-btn {
      font-family: Averta;
      line-height: 18px;
      letter-spacing: 0.25px;
      font-size: $font-size-2;
      color: $white;

      &:hover {
        color: $gray-light;

        svg {
          color: $gray-light;
        }
      }

      &.ui.labeled.icon.button {
        padding: 0 !important; //semantic override
        display: flex;
        align-items: center;
      }

      svg {
        margin-right: 10px;
        margin-bottom: 4px;
        padding-top: 1px;
      }
    }

    .selected-divider {
      margin-top: auto;
      margin-bottom: auto;
      font-size: 24px;
      margin-right: 10px;
      margin-left: 10px;
    }
  }
}

.recipient-discount-request-filters {
  width: 460px;
  margin-bottom: 16px;

  .date-form {
    z-index: 3; // used to overlay the sticky-column

    .date-range-picker {
      display: flex;
      flex-direction: column;
      position: inherit; // used to overlay the sticky-column
      label {
        color: $primary-medium !important;
        font-family: Averta !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: $font-size-1 !important;
        line-height: $font-height-1 !important;
        margin-bottom: 10px;
      }
    }
  }

}
