@import '../../Design/tokens';

.profile-settings {
  height: 100%;
  width: 100%;

  .profile-settings-container {

    .profile-settings-header {
      padding: $spacing-l $spacing-m;
      border-bottom: 1px solid $primary-light;
    }

    .profile-settings-body {
      padding: $spacing-l $spacing-m;

      .account-info-section {
        margin-bottom: $layout-xl;

        .account-info-section-header {
          @include body-reg-02;
          font-weight: 600;
          margin-bottom: $spacing-xl;
        }

        .account-info-row {
          display: flex;
          flex-direction: row;
          margin: $spacing-s 0;

          .account-info-label {
            @include body-reg-01;
            font-weight: 600;
            width: 140px;
          }

          .account-info-value {
            @include body-reg-01;
            flex: 1 0 auto;
          }
        }
      }
    }

    .app-button.change-password-link {
      font-size: $font-size-1 !important;
      line-height: $font-height-1 !important;
      font-weight: 600;
      height: auto;
      padding: 0 !important;
      background: transparent !important;
      justify-content: flex-start;

      &:hover {
        color: $blue-darker;
      }

      &:active {
        color: $blue-pressed;
      }
    }

  }

}
