@import '../../../Design/tokens';
@import '../../../Design/classes';

.modal-close-button {
  top: 12px;
  right: 8px;
  position: absolute;
}

.add-funding-source-modal {
  width: 600px !important;
  padding: 24px !important;

  .header-text {
    @include header-sans-semi-01;
    margin-right: 30px;
    margin-bottom: $spacing-m;
  }
}

.success-body {
  @include body-reg-03;
}

.radio-buttons {
  display: flex;
  margin-top: $spacing-m;

  label {
    color: $gray-darker !important;
    margin-right: $spacing-m;
  }

}

.bottom-right-button {
  bottom: -64px;
  right: 0;
  position: absolute;
}

.bottom-right-close-button {
  bottom: 24px;
  right: 24px;
  position: absolute;
}

.bank-account-form {
  margin-bottom: 66px
}

.error-text {
  color: $red-darker;
  margin-bottom: $spacing-m;
  font-size: 12px;
}

.success-message {
  margin-bottom: 124px;
}

.error-wrapper {
  padding-bottom: $spacing-m;
}

.full-error {
  height: 336px;
}
