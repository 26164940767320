@import "../../Design/tokens";

.covered-entity-container {
  background: $white;
  height: 100%;
  display: flex;
  flex-direction: column;

  &.scroll-page {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }

  &.padding-top {
    padding-top: 36px;
  }

  .tab-controller {
    &.divided {
      .ui.secondary.pointing.menu {
        margin: 0 2rem 0 2rem;
        padding: 0;
      }
    }
  }

  .ce-center-page-header.page-header {
    background: $white;
    border-bottom: none;
    margin-left: 28px;
  }

  .ce-pharmacy-summary {
    display: flex;
    flex-direction: row;
    margin: 0px 0px 25px 28px;

    div {
      padding: 0 8px;
      white-space: nowrap;
      @include body-reg-01;

      span.value {
        font-weight: 600;
        padding-left: 3px;
      }
    }

    div:not(:last-child) {
      border-right: 1px solid $gray-lighter-border;
    }
  }

  .ce-edit-banner {
    background-color: $blue-dark;
    min-height: 36px;
    padding: 8px 26px;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 1;

    p {
      @include body-semi-02;
      color: $white;
      font-weight: 600;
    }
  }

  .ce-tab {
    z-index: 0 !important; // override exisiting z-index to allow edit banner to overlap tabs
  }
}

.receipt-health-system-info {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $layout-m $layout-m;

  .health-system-name {
    margin-bottom: calc(2 * #{$spacing-xl});

    .label {
      font-size: $font-size-1;
      line-height: $font-height-1;
      text-transform: uppercase;
    }

    .value {
      font-size: $font-size-4;
      font-weight: 600;
      line-height: $font-height-3;
    }
  }

  .health-system-info-content-wrapper {
    width: 900px;

    .content-section {
      margin-bottom: $layout-xxl;

      .content-heading {
        @include body-reg-03();
      }

      .content-body {
        margin-top: $spacing-xl;
        margin-left: $spacing-s;
        display: flex;
        flex-direction: row;

        .content-col {
          flex: 1;

          .content-row {

            &:not(:last-child) {
              margin-bottom: $spacing-xxl;
            }

            .sub-heading {
              @include body-reg-01();
              font-weight: 600;
            }

            .content {
              @include body-reg-02();
            }
          }
        }
      }
    }
  }
}
