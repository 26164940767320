@import './../Design/tokens';


.right-navigation {
  background: $white;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  width: 60px;
  font-size: $font-size-1;
  line-height: $font-height-1;
  letter-spacing: 0.25px;
  z-index: 1000;
  padding: 1rem 0;

  .right-navigation-menu {
    justify-content: space-between;
  }
}

.right-navigation-icon {
  height: 40px;
  width: 40px;
  border-radius: 10px;
  margin: 0.5rem auto;

  &.profile {
    background: $gray-darker;
    cursor: pointer;
    color: $white;

    &:hover {
      background: $gray-hover;
    }

    &:active {
      background: $gray-pressed;
    }
  }

  &.notification {
    background: $gray-lighter-border;
  }
}

.help-button {
  width: 100%;
  svg {
    margin-bottom: $spacing-xxs;
  }
  span {
    @include body-reg-01;
  }

  &:hover {
    svg {
      path {
        stroke: $blue-darker;
      }
    }

    span {
      color: $blue-darker;
    }
  }

  &:active {
    svg {
      path {
        stroke: $blue-pressed;
      }
    }

    span {
      color: $blue-pressed;
    }
  }
}