@import '../../Design/tokens';

.dispute-code-list {
  display: flex;
  flex-direction: row;
  align-items: center;

  .dispute-code {
    cursor: pointer;
    margin-right: 6px;
    border-radius: 10px;
    border: 1px solid $primary;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    width: 20px;
    justify-content: center;
    line-height: 18px;
    height: 20px;
    font-weight: 400;

    &.padding-top {
      padding-top: 1px;
    }

    &:hover {
      color: $blue-hover;
      border-color: $blue-hover;
    }
  }
}

.ui.popup.visible {
  .dispute-description {
    text-align: center;
  }
}