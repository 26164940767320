@import '../../Design/tokens';
@import '../../Design/classes';

.edit-team-modal {

  .edit-team-name {
    @include body-reg-02;
    margin-bottom: $spacing-s;
    font-weight: 600;
  }

  .edit-team-count {
    @include body-reg-02;
    margin-top: $spacing-s;
    text-align: right;
    font-weight: 600;
  }

  .edit-team-search{
    margin-bottom: $spacing-s;
    width: 100%;
  }

  .edit-team-list {
    height: 400px;
    overflow-y: auto;
    border: 1px solid $gray-lighter-border;

    .edit-member-row {
      cursor: pointer;
      padding: $spacing-s;

      &:hover:not(.disabled) {
        background: $gray-lighter;
      }

      &.disabled {
        cursor: auto;
      }

      &.pending {
        .ui.checkbox {
          label {
            color: $gray-medium-light
          }
        }
      }

      .ui.checkbox {
        width: 100%;

        label {
          @include body-reg-02;
          line-height: $font-height-1;
        }

        input:checked~label:after {
          background: black;
          color: $white;
        }

        &.disabled {
          label {
            opacity: 1;
          }

          input~label:active:before {
            background: $white;
          }

          input:not(:checked)~label:before {
            border: 1px solid $gray-lighter;
          }

          input:checked~label:after {
            background: $gray-medium-light
          }
        }
      }
    }
  }
}