@import '../../Design/tokens';
@import '../../Design/classes';


.onboarding-layout-header {
  font-family: $font-family-sans-reg;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.25px;
  padding: 32px 36px 0 36px;
  margin-bottom: 12px;

  .title {
    font-weight: 600;
    font-size: $font-size-4;
    line-height: $font-height-3;
    letter-spacing: 0.25px;
    color: $primary;
  }

  .sub-title {
    margin-top: 8px;
    font-size: $font-size-2;
    line-height: $font-height-2;
    color: $primary-medium;
  }
}

.onboarding-layout-children {
  flex: 1;
  padding: 24px 36px;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .title {
    font-weight: 600;
    font-size: $font-size-3;
    line-height: $font-height-3;
    letter-spacing: 0.25px;
    color: $primary;
  }

  .onboarding-layout-body {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.onboarding-layout-footer {
  display: flex;
  align-items: center;
  height: 84px;
  background: $white;
  padding: 32px 24px;
}

.onboarding-layout-navigation {
  button {
    background: transparent; // doesn't do anything on mac os, needed for windows
  }
}

.onboarding-layout-cta {
  display: flex;
  flex: 1;
  justify-content: flex-end;

  button:disabled {
    cursor: default;
  }
}

.onboarding-button-spacing {
  margin-right: $spacing-m;
}


.section-header {
  font-size: $font-size-2;
  line-height: $font-height-2;
  letter-spacing: 0.25px;
  font-weight: 600;
  color: $primary-medium;
  margin-bottom: 16px;

  &.margin-top {
    margin-top: 24px;
  }
}

.detail-field {
  margin-bottom: 8px;

  &.multiple {
    display: flex;
    flex-direction: row;

    .label {
      border-bottom: none;
    }

    .value:not(:last-child),
    .label:not(:last-child) {
      padding-right: 24px;
    }

    border-bottom: 1px solid $gray-lighter-border;

  }

  .value {
    font-family: Averta;
    font-weight: 600;
    font-size: $font-size-2;
    line-height: $font-height-2;
    letter-spacing: 0.25px;
    color: $primary;
  }

  .label {
    font-family: Averta;
    font-size: $font-size-1;
    line-height: $font-height-1;
    letter-spacing: 0.25px;
    color: $primary-medium;
    padding-top: 4px;
    padding-bottom: 8px;
    border-bottom: 1px solid $gray-lighter-border;

    &.no-border {
      border-bottom: none;
    }
  }
}

.detail-line {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  &.with-border {
    border-bottom: 1px solid $gray-lighter-border;
  }

  .label {
    font-size: $font-size-2;
    line-height: $font-height-2;
    letter-spacing: 0.25px;
    font-weight: 600;
    color: $primary-medium;
    margin-bottom: 8px;
  }

  .value {
    font-size: $font-size-2;
    line-height: $font-height-2;
    letter-spacing: 0.25px;
    font-weight: 600;
    color: $gray-darker;
    padding-left: 28px;
  }
}

.ui.form.input-form {

  .input-error-message {
    color: $red-darker;
    margin-bottom: $spacing-m;
    font-size: $font-size-1;
  }

  .field {
    label {
      font-weight: 600;
      font-size: $font-size-1;
      line-height: $font-height-1;
      letter-spacing: 0.25px;
      color: $gray-medium;
    }
  }

  .ui.input,
  .ui.selection {
    background: $white;
    color: $gray-darker;
    min-height: 40px;
    max-height: 40px;

    :focus {
      color: $gray-darker;
    }
  }

  &.full {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
}

.input-error {
  label {
    color: $red-darker !important;
  }

  .ui.input {

    input,
    input:focus {
      background: $red-lighter !important;
      border: 1px solid $red-darker;
      border-radius: 1px;
    }
  }

  margin-bottom: 4px !important;
}

.info-banner {

  &-warning {
    background-color: $golden-extralight;
    color: $primary;
  }

  &-error {
    background: $red-light;
    color: $red-darker;
    font-size: 14px;
    line-height: 20px;

    .banner-contents {
      display: flex;
    }

    .contents {
      font-weight: 400;

    }
  }

  &-warning,
  &-error {
    padding: 16px 20px;
    font-size: 16px;
    display: flex;
    flex-direction: row;

    .icon {
      margin-right: 15px;
      font-size: $font-size-4;
    }
  }
}

.full-screen-message {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .header {
    font-weight: 600;
    font-size: $font-size-4;
    line-height: $font-height-3;
    letter-spacing: 0.25px;
    color: $primary;
  }

  .details {
    padding: 0px 50px;
    margin-top: 16px;
    font-size: $font-size-2;
    line-height: $font-height-2;
    color: $primary-medium;
  }
}

.edit-button {
  @include body-reg-01;
  background: transparent;
  border: none;
}

.header-with-edit {
  display: flex;
  justify-content: space-between;
}

.onboarding-checkbox {
  padding-left: 24px;

  .ui.checkbox {
    label {
      font-size: $font-size-1;
      line-height: $font-height-1;
      letter-spacing: 0.25px;
      color: $primary;
      padding-left: 28px;
    }

    input:focus~label {
      color: $primary;
    }

    input:checked~label:after {
      background: $primary;
      color: $white;
    }
  }
}

.covered-entity-list {
  overflow-y: auto;
  margin-bottom: 24px;


  .covered-entity-details {
    border-bottom: 1px solid $gray-lighter-border;

    .ce-summary {
      display: flex;
      flex-direction: row;
      padding-top: 18px;
      padding-bottom: 18px;
      cursor: pointer;
      @include body-reg-02;
      font-weight: 600;

      .ce-id {
        margin-left: 20px;
      }

      .ce-name {
        margin-left: 10px;
      }

      .icon.collapsed {
        color: $primary-light;
      }

      .icon.expanded {
        color: $primary;
      }
    }

    .ce-addresses {
      display: flex;
      flex-direction: row;
      margin-left: 40px;
      margin-bottom: 18px;
      @include body-reg-01;

      .billing-address {
        margin-left: 25px;
      }

      .address-header {
        color: $primary-medium;
        margin-bottom: 8px;
      }
    }
  }
}
