@import '../../../Design/classes';
@import '../../../Design/tokens';

.card-container {
  background: $white;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 100%;
  margin-bottom: 16px;
  padding: 0;
  transition: all ease-in-out 0.2s;
  width: 100%;

  .card-title {
    .view-content {
      visibility: hidden;
    }

    &:hover {
      .view-content {
        visibility: initial;
      }
    }
  }

  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }

  .card-item {
    min-width: 84px;
    text-align: left;
    width: auto;
  
    .card-item-value {
      @include body-reg-02();
      font-weight: 600;
      padding-bottom: 4px;

      &.small {
        font-size: 12px;
        font-weight: 400;
        padding-bottom: 0;
      }
    }
  
    .card-item-label{
      @include body-reg-01();
      color: $gray-medium;

      &.small {
        font-size: 10px;
      }
    }
  }
}