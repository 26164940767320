@import '../../../Design/tokens';

.dr-account-admin-confirmation {

  .associated-entities-info {
    .section-header {
      margin-top: $layout-s;
      margin-bottom: 0;

    }

    .confirm-check {
      padding-top: $spacing-xxl;
      padding-bottom: $spacing-s;

      .onboarding-checkbox {
        padding-left: 0;

      }

    }

    .health-system-name{
      margin-top: 14px;
      color: $primary;
    }

    .hs-label{
      color: $primary-medium;
      margin-top: 4px;
    }

    .covered-entity-list {
      margin-bottom: 0;
    }
  }

}
