@import '../../../Design/classes';
@import '../../../Design/tokens';

.page-summary {
  margin: $spacing-xl 0;
  position: relative;
  z-index: 1;

  .page-summary-body {
    display: flex;
    flex-direction: row;

    .page-summary-totals {
      width: 380px;
      min-width: 250px;
    }

    .page-summary-bar {
      width: 520px;
      display: flex;
      flex-direction: column;
      align-self: flex-end;
      padding-left: $spacing-xxl;
    }
  }
}

.summary-header {
  @include display-serif-01;
  margin-bottom: $spacing-m;

  .summary-header-total {
    color: $evergreen-dark;
  }
}

.summary-item-container {
  border-bottom: 1px solid #DCDFE5;
  display: flex;
  flex-direction: row;
  height: 32px;
  justify-content: space-between;
  padding-bottom: 6px;
  padding-top: 8px;

  .summary-item-title {
    @include body-reg-01();
    color: $gray-medium;
  }

  .summary-item-value {
    @include body-reg-02();
    color: $gray-darker;
    font-weight: 600;
  }
}