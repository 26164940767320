@import '../../../Design/tokens';
@import '../../../Design/classes';

.drawer {
  &.outside-zone {
    display: none;

    &.active {
      position: fixed;
      left: 0;
      top: 0;
      display: initial;
      height: 100vh;
      width: calc(100vw - 410px);
      z-index: 1001;
    }
  }

  &.portal {
    position: fixed;
    right: 0;
    top: 0;
    background: $white;
    height: 100%;
    z-index: 1001;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    width: 410px;

    /* workaround as transition portal set display to block */
    &.visible.transition {
      display: flex !important;
    }

    &.hasSecondary {
      width: 434px;

      .secondary-button {
        right: 422px;
      }

    }

    &.hasMultipleAttached {
      .secondary-button {
        &.secondary-drawer-open {
          right: 922px;
        }
      }
    }

    .drawer-shadow {
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);

    }

    .secondary-tab {
      background: $bg-gray;
      width: 24px;
      min-width: 24px;
    }

    .secondary-button {
      position: absolute;
      top: 50%;
      right: 398px;
      border: 1px solid $gray-light;
      background: $white;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      cursor: pointer;

      svg {
        color: $primary;
        position: absolute;
        top: 3px;
        right: 4px;
      }

      &:hover {
        background: $blue-darker;

        svg {
          color: $white;
        }
      }

      &.with-text {
        @include body-reg-01();
        width: 48px;
        box-sizing: border-box;
        border-radius: 100px;
        text-align: right;
        padding-top: 2px;
        padding-right: 12px;

        svg {
          right: 28px;
        }

        &:hover {
          color: $white;
        }
      }

      &.secondary-drawer-open {
        right: 820px;
        z-index: 1; // overlay secondary drawer

        svg {
          right: 3px;
        }
      }
    }
  }

  .secondary-drawer {
    background: $bg-gray;
    width: 420px;
    max-width: 557px;
    height: 100%;
    overflow-y: auto;
    -ms-overflow-style: none;
    position: absolute;
    top: 0px;
    right: 410px;
    transition-timing-function: ease;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);

    &::-webkit-scrollbar {
      display: none;
    }

    /* workaround as transition portal set display to block */
    &.visible.transition {
      display: flex !important;
    }

    &.hasMultipleAttached {
      width: 524px;
    }
  }

  .ui.divider {
    width: 100%;
    margin-right: 16px;
  }

  .attribute-value {
    @include body-reg-02;
    font-weight: 600;
  }

  .attribute-label {
    @include body-reg-01;
    color: $gray-medium;
    text-transform: uppercase;
  }

  .validation-reasons {
    margin-left: 16px;
  }

  .drawer-layout {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-y: hidden;
    max-width: 410px;
    width: 410px;
  }

  .drawer-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-y: hidden;
    overflow-x: hidden;

    .action-buttons-container {
      width: 410px;
      border-top: 1px solid $gray-lighter-border;

      .action-buttons-container-btn-row {
        padding: 15px;
        flex: 1;
      }

      .ui.basic.button {
        width: 184px;
        height: 36px;
        margin: 0;
      }

      &.payment-user {
        min-height: auto !important;
      }

      &.failed {
        .action-buttons-container-btn-row {
          margin-top: 16px;
        }
      }

      .ui.basic.button.approve {
        padding: 7px;
        background-color: $evergreen-extralight !important;
        border: 1px solid $evergreen-dark;
        color: $evergreen-dark !important;
        box-shadow: none;
      }

      .ui.basic.button.reject {
        background: $red-light !important;
        border: 1px solid $red-darker;
        color: $red-darker !important;
        box-shadow: none;
      }

      .ui.basic.button.adjust {
        background: $evergreen-extralight !important;
        border: 1px solid $evergreen-dark !important;
        color: $evergreen-dark !important;
      }
    }

    .automated-message-container {
      margin-top: 16px !important;
      margin-left: 16px;

      .status-label {
        white-space: nowrap;
        width: auto;
        height: auto;
      }

      .separator-automation-message {
        margin-left: 10px !important;
        width: 73%;
        margin-top: auto !important;
        margin-bottom: auto !important;
        margin-right: 10px !important;
      }

      .automation-messages-container {
        min-height: 40px;


        .automated-messages {
          margin-top: 5px;
        }
      }

      .stacking-loader {
        height: 40px;
        min-height: 40px;
        width: 380px;
        min-width: 380px;
        position: relative; // center loading spinner in middle of div
      }
    }

  }

  .drawer-header-with-body {
    overflow: auto;

  }

  .drawer-body {
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px 16px 16px 16px;
  }

  .past-invoice-link {
    display: flex;
    padding: 16px 16px 16px 28px;

    svg {
      color: $blue-darker;
      margin-left: 4px;
    }
  }

  .request-link {
    display: flex;
    padding: 16px 16px 16px 28px;

    svg {
      color: $blue-darker;
      margin-left: 4px;
    }
  }

  .drawer-details-header {
    @include body-semi-02();
    line-height: 28px;
    padding-bottom: 8px;
    padding-left: 8px;
    
    &.margin-top {
      margin-top: 24px;
    }
  }
}
