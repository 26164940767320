@import '../../Design/tokens';
@import '../../Design/classes';

.welcome-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: auto;
} 

.welcome-message-container {
    display: flex;
    flex-direction: column;
    width: 488px;
    font-family: $font-family-sans-reg;
    font-style: normal;
    font-weight: bold;
    font-size: 54px;
    line-height: 62px;
    color: $primary;
    margin-right: 72px;
    p { 
        font-weight: normal;
        font-size: $font-size-3;
        margin-top: $layout-xxl;
        line-height: $font-height-3;
        letter-spacing: 0.25px;
        width: 352px;
        color: $gray-medium;
    }
}

.logo {
    height: 20px;
    width: 144px;
    margin-bottom: 84px;
}

.steps-container {
    width: 488px;
    box-shadow: 0px 4px 20px rgba(66, 75, 94, 0.13);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    height: 616px;
}

.steps {
    display: flex;
    flex-direction: column;
    margin-top: 69px;
    margin-left: 29px;
    height: 100%;
    position: relative;
}

.steps-heading {
    margin-bottom: 56px;
    font-family: $font-family-sans-reg;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.25px;
    color: $primary;
}

.steps-list {
    font-family: $font-family-sans-reg;
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-3;
    line-height: $font-height-3;
    letter-spacing: 0.25px;
    ol {
        padding: 0;
        margin: 0 0 0 29px;
    }
    li {
        margin-bottom: 46px;
    }
}

.steps-button {
    width: 433px;
    position: absolute;
    bottom: 32px;
}