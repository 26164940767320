@import '../../Design/tokens';

.loading-bar {
  width: 100%;
  min-width: 100%;
  background-color: $gray-lighter;

  .loading {
    width: 25%;
    min-width: 25%;
    height: 4px;
    position: relative;
    animation-name: bar-animation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    background-color: $blue-darker;
  }
}

@keyframes bar-animation {
  0% {
    left: 0px;
    top: 0px;
  }


  100% {
    left: 75%;
    top: 0px;
  }
}
