@import '../../Design/tokens';

.formStepper-container {
  flex: 1 0;
  padding: 0 24px;
  width: 248px;
  font-family: $font-family-sans-reg;

  .stepper-text {
    display: flex;
    align-items: center;
    margin-left: 12px;
    font-weight: 400;
  }

  .stepLink {
    padding: 0;
    border: none;
    cursor: pointer;
    color: $cobalt-medium;
    background-color: $white;
  }

  .stepper {
    color: $primary-light;
  }

  .stepper.active {
    color: $primary;
  }

  .step-divider {
    border: 1px solid $primary-light;
    background: $primary-light;
    height: 21px;
    width: 2px;
    margin: 4px 7px;
    border-radius: 5px;
  }

  .stepper:last-child .step-divider {
    display: none;
  }
}
