@import '../../../Design/tokens';
@import '../../../Design/classes';


.invite-controller {

  .controller-checkbox {
    margin-top: $layout-m;
    margin-bottom: $layout-m;
  }

  .controller-title {
    @include body-reg-03;
  }

  .controller-info {
    @include body-reg-02;
    color: $primary-medium;
    padding-bottom: 18px;

    &.bold {
      font-weight: 600;
      padding-bottom: 16px;
    }
  }

}
