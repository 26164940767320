@import '../../Design/tokens';

.edit-date-override {
  .edit-date-override-modal {
    .error-notification {
      position: absolute;
      top: -43px;
      width: 100%;
      right: 0;
      background: $red-light;
      padding: 1rem;
      color: $red-darker;
    }

    .edit-date-override-header-container {
      button {
        top: 12px;
        right: 8px;
        position: absolute;
      }
    }

    .delta {
      padding-top: 20px;
    }

    // date-picker
    .date-container {
      padding-top: 15px;
      display: flex;
      justify-content: center;
      margin-bottom: 25px;
      margin-right: 50px;

      .SingleDatePicker {
        width: 120px;
        margin-right: 25px;

        .SingleDatePickerInput_1 {
          width: 100%;

          .DateInput {
            width: 100%;
          }

          input {
            font-style: normal;
            text-align: center;
          }
        }
      }

      .start-date-override-container {

        .start-date-override-label-disabled {
          color: $gray-medium-light;
        }

        .start-date-override-label-disabled {

          .SingleDatePicker {
            width: 120px;
            margin-right: 25px;

            .SingleDatePickerInput_1 {
              width: 100%;

              .DateInput {
                width: 100%;

                .DateInput_input__disabled {
                  background: $white;
                }
              }

              input {
                font-style: normal;
                text-align: center;
              }
            }
          }
        }

        .DateInput_input__focused {
          color: $blue-dark;
          border-bottom: 2px solid $white;
        }
      }
    }

    .remove-start-override-container {
      position: absolute;
      right: 15px;
      top: 140px;

      button {
        height: 34px;
      }
    }

    .date-container-two {
      display: flex;
      justify-content: center;
      margin-bottom: 25px;
      margin-right: 50px;

      .SingleDatePicker {
        width: 120px;
        margin-right: 25px;

        .SingleDatePickerInput_1 {
          width: 100%;

          .DateInput {
            width: 100%;
          }

          input {
            font-style: normal;
            text-align: center;
          }
        }
      }

      .term-date-override-container {

        .term-date-override-label-disabled {
          color: $gray-medium-light;
        }

        .term-date-override-date-picker-disabled {

          .SingleDatePicker {
            width: 120px;
            margin-right: 25px;

            .SingleDatePickerInput_1 {
              width: 100%;
              border: 1px solid $gray-medium-light;
              border-radius: 1px;

              .DateInput {
                width: 100%;

                .DateInput_input__disabled {
                  background: $white;
                }
              }

              input {
                font-style: normal;
                text-align: center;
              }
            }
          }
        }

        .DateInput_input__focused {
          color: $blue-dark;
          border-bottom: 2px solid $white;
        }
      }
    }

    .remove-term-override-container {
      position: absolute;
      right: 15px;
      bottom: 85px;

      button {
        height: 34px;
      }
    }

    .button-white {
      margin-right: 10px;
    }

  }
}
