@import './../Design/tokens';

.remittance-advice-home {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .amount-column-failed {
    color: $red-darker !important;
  }

  .remittance-advice-home-page-header {
    margin-left: 28px;
  }

  &-pagination-sube-header {
    width: 95%;
    margin-left: 20px;
  }

  .info-banner-content {
    padding: $spacing-m $spacing-l;
  }

  .remittance-advice-home-main-container {
    flex: 1;
  }

  .no-transactions-msg-container {
    margin-left: auto;
    margin-top: 18%;
    margin-right: auto;

    .content {
      text-align: center;
      width: 322px;
      margin: auto;
    }

    .header {
      text-align: center;
    }
  }

  .remittance-summary-without-filter {
    margin: 0 0 0 20px;
  }

  .remittance-advice-loader {
    position: fixed !important;
    top: 400px !important;
  }
}

.remittance-overview {
  background: $white;
}

.remittance-summary-details-card.open {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  min-width: 1025px;

  .accordion {
    .remittance-row {
      border-bottom: 1px $gray-light solid;
    }
  }

  .content.active {
    .accordion {
      .content.active {

        .remittance-summary-details-table {
          border-collapse: collapse;

          tbody {
            tr:last-child td {
              border-bottom: 1px $gray-light solid;
            }

            tr:last-child td:first-of-type,
            tr:last-child td:last-of-type {
              border-bottom: none;
            }

          }
        }

      }
    }

  }

}

.remittance-summary-details-card {
  margin-top: 10px;
  background-color: $blue-lighter;
  border-radius: 8px;
  margin-bottom: 10px;
  width: 100%;
  min-width: 1025px;

  .clickable {
    cursor: pointer;
  }

  .accordion {
    margin: 0 !important;

  }

  .ce-row {
    height: 56px;
    display: flex;
    align-items: center;

    .remittance-detail-link {
      width: 201px;
      height: 32px;
      color: $primary;
      font-size: 12px;
      font-weight: 600;
      visibility: hidden;
      padding: 0;
      background: $white;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
    }

    .clickable {
      width: 100%;
      height: 56px;
      justify-content: space-between;
      align-items: center;
      margin: 0 20px 0 20px;
      border-bottom: 1px $gray-light solid;
    }

    .clickable.open {
      border-bottom: none;
    }

  }


  .content.active {
    padding-top: 0 !important;

    .accordion:last-child {

      .ce-row {
        border-bottom: none !important;
      }

    }

    .accordion {
      background: $bg-gray;

      .ce-row:hover {
        background: $gray-lighter;

        .remittance-detail-link {
          visibility: visible;
          border: 1px solid $gray-darker; // add border to 'Go to Remittance Details' button

          svg {
            path {
              fill: $blue-hover;
            }
          }

          .hover-arrow {
            margin-left: 8px;
          }

        }

        .remittance-detail-link:hover {
          background: $gray-lighter;
          color: $primary;
        }

        .remittance-detail-link:active {
          background: $gray-light;

          svg {
            path {
              fill: $blue-pressed;
            }
          }
        }

      }
    }

    .accordion:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      .clickable {
        border-bottom: none;
      }

      .content.active {
        border-bottom: none;

        .remittance-summary-details-table {
          border-collapse: separate;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;

          tbody {
            tr {

              td {
                border-bottom: none !important;
              }

              td:first-child {
                border-bottom-left-radius: 8px;
              }

              td:last-child {
                border-bottom-right-radius: 8px;
              }

            }

          }
        }

      }

    }

  }

  .main-container {
    justify-content: space-between;
    height: 76px;

    .information-column {
      width: 50%;
      align-self: center;

      .information-row {
        justify-content: space-around;

        .download {
          align-self: center;

          &:hover {
            svg {
              path {
                fill: $blue-hover;
              }
            }
          }
        }
      }
    }

    .date-column {
      padding-left: 12px;
      align-self: center;
      width: 50%;

      .remittance-date {
        width: 112px;
        height: 36px;
        line-height: 24px;
        border-radius: 100px;
        background: $blue-light;
        border: 1px solid $cobalt-extralight;
        box-sizing: border-box;
        padding: 6px;
        text-align: center;
      }

      .remittance-dash {
        padding-left: 4.86px;
        padding-right: 4.86px;
        align-self: center;
      }
    }
  }

  .entity-id {
    color: $primary;
    margin-left: 16px;
  }

  .numb-mfrs {
    color: $primary;
    margin-left: 8px;
  }

  .label {
    color: $primary-medium;
  }

  .show-hide-button {
    width: 100%;
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    cursor: pointer;
    font-family: $font-family-sans-reg;
    font-size: $font-size-1;
    letter-spacing: 0.25px;
    line-height: $font-height-1;
    color: $primary;

    i {
      color: $primary-medium;
      margin-left: 14px;
    }

    &:hover {
      color: $blue-hover;

      i {
        color: $blue-hover;
      }
    }
  }
}

.remittance-summary-details-card:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.remittance-summary-details-year {
  min-width: 600px;
  max-width: 1025px;

  .year-value {
    margin-top: 24px;
    margin-bottom: 16px;
    margin-left: 24px;
  }

  .month-container {
    margin-left: 30px;
    margin-left: 30px;
    min-height: 40px;
    min-width: 1025px;
  }

  .month-row {
    margin-left: 30px;
    min-height: 40px;
    min-width: 1025px;

    div.month {
      margin-top: 10px;

      .loading {
        margin-left: 300px;
      }
    }

    .month-accordion-button {
      width: 100%;
      display: flex;
      align-items: center;
      border: none;
      background: transparent;
      cursor: pointer;
      outline: none;

      .remittance-month {
        padding-left: 12px;
      }

      &:hover {
        color: $blue-hover;
        outline: 1.5px solid $blue-hover;
      }
    }

    i {
      display: inline;
    }
  }
}

.remittance-summary-details-table {
  background: $bg-gray;
  border: none !important;

  thead th {
    background: $bg-gray !important;
    border-bottom: none !important;
  }

  tr {
    height: 48px;
    cursor: pointer;
    background: $bg-gray;

    svg {
      position: relative;
      top: 2px;
      margin-left: 8px;

      path {
        fill: $blue-hover;
      }
    }
  }

  tr:hover {
    background: $primary-extra-light;

    .hover-arrow {
      visibility: visible;
    }
  }

  tr:active {
    background: $gray-light;
  }

  td {
    border-top: none !important;
  }

  .with-arrow {
    display: flex;
    justify-content: space-between;

    .hover-arrow {
      visibility: hidden;
    }

  }

  .header-cell {
    font-style: normal;
    font-size: $font-size-1;
    line-height: $font-height-1;
    color: $gray-darker;
    letter-spacing: 0.25px;
    font-family: $font-family-sans-semi;
  }

  .body-cell {
    font-family: Averta;
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-1;
    line-height: $font-height-1;
    color: $gray-darker;
  }

  .border-spacing-cell {
    width: 20px;
  }
}

.remittance-request-center {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: $white;

  .remittance-request-center-loader {
    position: fixed !important;
    top: 400px !important;
    left: 54% !important;
  }

}

.tab-controller.remittance-request-center-tabs {
  padding-top: 32px;
  padding-left: 20px;
  padding-right: 20px;

  .ui.secondary.pointing.menu {
    padding-left: 0;
    padding-right: 0;
  }

  & a.active.item {
    div.ui.label.remittance-tab-count {
      background: $gray-darker;
    }
  }

  & a.item:hover {
    div.ui.label.remittance-tab-count {
      background: $gray-darker;
    }
  }

  div.ui.label.remittance-tab-count {
    width: auto;
    height: auto;
    background: $gray-medium-light;
    border-radius: 50px;
    color: $white;
    text-align: center;
    font-family: Averta;
    font-style: normal;
    font-weight: 600;
    font-size: $font-size-1;
    line-height: $font-height-1;
    padding: 4px 8px 3px 8px;
    margin-left: 8px;
  }
}