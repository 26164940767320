@import '../../../../Design/tokens';
@import '../../../../Design/classes';

.status-label {
  width: 65px;
  height: 20px;
  border: 1px solid $primary;
  border-radius: 3px;
  color: $primary;
  font-family: $font-family-sans-semi;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: .25px;
  padding: 4px 6px;

  &.evergreen {
    background: $evergreen-extralight;
    color: $evergreen-dark;
    border-color: $evergreen-medium;
  }

  &.redwood {
    background: $red-light;
    color: $red-darker;
    border-color: $red-medium;
  }

  &.pending-reversal {
    background: $gray-lighter;
    color: $primary-medium;
    border-color: $primary-light;
  }

  &.reversed {
    background: $primary-medium;
    color: $white;
    border-color: $primary-medium;
  }

  &.darkcolbalt {
    background: $blue-light;
    color: $cobalt-dark;
    border: 1px solid $blue-medium;
  }

  &.darkorange {
    background: $golden-extralight;
    color: $golden-dark;
    border: 1px solid $golden-medium;
  }

  &.medical {
    background: $gray-lighter;
    color: $primary;
    border: 1px solid $gray-medium;
    font-family: $font-family-sans-reg;
  }

  &.lightblue {
    background: $blue-lighter;
    color: $blue-darker;
    border-color: $blue-medium;
  }
}
