@import '../../Design/tokens';
@import '../../Design/classes';

.assign-ce-modal {

  .assign-ce-actions {
    text-align: right;

    button {
      @include body-reg-01;
      font-weight: 600;
      padding: 0;
      margin-bottom: $spacing-xs;
      margin-left: $spacing-xxl;

      &:hover {
        color: $blue-hover !important;
      }
    }
  }

  .assign-ce-dropdown.ui.multiple.dropdown {
    height: auto;

    &.visible {
      max-height: 100%;
      overflow-y: visible;
    }

    >input.search {
      height: 30px;
      margin: 0 !important;
    }

    .ui.label {
      @include body-reg-01;
    }

    .assign-ce-option {
      display: flex;
      flex-direction: row;

      &:hover {
        div {
          color: #2896f9;
        }
      }

      .ce-option-id {
        @include body-reg-01;
        width: 105px;
      }

      .ce-option-name {
        @include body-reg-01;
        font-weight: 600;
        flex: 1
      }
    }

    .selected.item {
      div {
        color: #3764F9;
      }
    }
  }
}