@import '../../../Design/tokens';

.dr-controller-confirmation {

  .confirm-check {
    padding-top: $spacing-xxl;
    padding-bottom: $spacing-s;

    .onboarding-checkbox {
      padding-left: 0;
    }
  }
}
