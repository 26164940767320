@import '../../../Design/tokens';

.invite-account-admin {
  .detail-field {
    .label {
      font-size: $font-size-2;
      line-height: $font-height-2;
    }
  }

  .onboarding-checkbox {
    margin-top: $spacing-xl;

  }

  .input-form {
    .app-button {
      margin-top: $layout-m;
    }
  }
}
