@import './../Design/tokens';

.recipient-transaction-label {
  width: 65px;
  height: 20px;
  color: $primary;
  font-family: $font-family-sans-reg;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: .25px;
  padding: 4px 6px;
  white-space: nowrap;

  &.submitted {
    color: $cobalt-dark;
    background: $cobalt-extralight;
    border: 1px solid $cobalt-light;
    border-radius: 3px;
  }

  &.review {
    color: $white;
    background: $golden-dark;
    border: 1px solid $golden-dark;
    border-radius: 3px;
  }

  &.processing {
    color: $golden-dark;
    background: $golden-extralight;
    border: 1px solid $golden-medium;
    border-radius: 3px;
  }

  &.paid {
    color: $evergreen-dark;
    background: $evergreen-extralight;
    border: 1px solid $evergreen-light;
    border-radius: 3px;
  }

  &.paid-adjust {
    color: $evergreen-dark;
    background: $evergreen-extralight;
    border: 1px solid $evergreen-light;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &.adjust-paid {
    color: $white;
    background: $evergreen-dark;
    border: 1px solid $evergreen-dark;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &.failed {
    color: $red-darker;
    background: $red-light;
    border: 1px solid $red-medium;
    border-radius: 3px;
  }

  &.pending-reversal {
    color: $primary-medium;
    background: $primary-extra-light;
    border: 1px solid $primary-light;
    border-radius: 3px;
  }

  &.reversed {
    color: $white;
    background: $primary-medium;
    border: 1px solid $primary-medium;
    border-radius: 3px;
  }

  &.null-reversal {
    color: $primary-light;
    background: $white;
    border: 1px solid $primary-light;
    border-radius: 3px;
  }
}

.header-text .recipient-transaction-label {
  font-size: 14px;
}

.recipient-transaction-drawer-failure-msg {
  width: 410px;
  min-width: 410px;
  position: relative;
  min-height: 48px;
  background: $red-light;
  border-radius: 1px;
  margin-bottom: 2px;

  .failure-code {
    color: black;
    position: relative;
    top: 6px;
    left: 28px;
  }

  .failure-message {
    color: black;
    position: relative;
    top: 8px;
    left: 28px;
    font-weight: 300;
  }
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.recipient-transaction-drawer-adjustment-container-outside {
  width: 99.6%;
  background: rgb(185, 185, 185);
  clip-path: polygon(3% 0%, -11% 100%, 100% 100%, 100% 0%);
  height: 50px;
  margin-top: 4px;
  margin-bottom: 12px;

  .recipient-transaction-drawer-adjustment-container {
    width: 99.3%;
    margin-left: auto;
    margin-right: auto;
    background: #F4F4F4;
    position: relative;
    top: 1px;
    left: 0px;
    height: 48px;
    clip-path: polygon(3% 0%, -11% 100%, 100% 100%, 100% 0%);

    .recipient-transaction-drawer-adjustment-text {
      color: black;
      position: relative;
      top: 6px;
      left: 12px;
    }

    .recipient-transaction-drawer-adjustment-text-secondary {
      color: black;
      position: relative;
      top: 8px;
      left: 12px;
      font-weight: 300;
    }
  }
}

.discount-per-unit-hover:hover {
  background: #dcdfe6;
  border-radius: 4px;
}
