@import '../../../Design/tokens';
@import '../../../Design/classes';

.dwolla-error-message {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .error-email {
    text-decoration: underline;
  }

  &.modal {
    height: 80%;
  }

  .header {
    font-weight: 600;
    font-size: $font-size-4;
    line-height: $font-height-3;
    letter-spacing: 0.25px;
    color: $primary;
  }

  .details {
    padding: 0px 50px;
    margin-top: 16px;
    font-size: $font-size-2;
    line-height: $font-height-2;
    color: $primary-medium;
  }
}
