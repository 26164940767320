@import '../../Design/tokens';
@import '../../Design/classes';

.login-page {
  display: flex;
  height: 100%;
  overflow: auto;
  background-color: $white;

  &.bg-verify {
    background-image: linear-gradient(-30deg, $white 45%, #FEF8F4 45%);
  }

  &.bg-request {
    background-image: linear-gradient(-30deg, $white 45%, #F5FFFF 45%);
  }

  .login-page-layout {
    flex: 1 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $layout-xl;

    &.two-column {
      margin: auto;
      min-width: 1074px;
      max-width: 1208px;
      width: 100%; //needed for IE11 to position correctly
    }
  }

  .login-page-column-left {
    position: relative;
    flex: 1 1 auto;
  }

  .login-page-column-right {
    position: relative;
    width: 490px;
  }

  .login-page-column-right-footer {
    @include body-reg-02;
    position: absolute;
    width: 490px;
    text-align: center;
    color: $gray-medium;
    margin-top: $spacing-xl;
    margin-bottom: $spacing-xl;
  }
}

.login-welcome-container {
  width: 392px;

  .logo {
    height: 20px;
    width: 144px;
    margin-bottom: 84px;
  }

  .welcome-header {
    font-family: $font-family-sans-reg;
    font-size: $font-size-5;
    letter-spacing: 0.25px;
    line-height: $font-height-5;
    font-weight: 600;
    color: $primary;
    margin-bottom: 45px;
  }

  .welcome-text {
    @include body-reg-03;
    color: $gray-medium;
    margin-bottom: $spacing-xl;

    &.verify {
      width: 358px
    }
  }

  .app-button.ao-button {
    background: transparent;
    border: 1px solid $primary;

    &:hover {
      background: $gray-lighter;
    }

    &:active {
      background: #DCDFE6;
    }
  }
}

.login-form-container {
  background: $white;
  padding: 64px 58px 96px 58px;
  box-shadow: 0 4px 20px rgba(66, 75, 94, .13);
  border-radius: 5px;

  &.padding-small {
    padding: 64px 58px 72px 58px;
  }

  .login-form-logo {
    margin-bottom: $layout-xl;
  }

  .login-form-header {
    font-family: $font-family-sans-reg;
    font-size: $font-size-4;
    letter-spacing: 0.25px;
    line-height: 25px;
    font-weight: 600;
    color: $primary;
  }

  .login-form-body {
    @include body-reg-02;
    margin-top: $spacing-m;
    margin-bottom: $spacing-xl;
  }

  .login-form-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .login-form.ui.form {

    .field label {
      @include body-reg-01;
      font-weight: 600;
    }

    .password-label {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      label {
        flex: 1;
        margin-bottom: 4px;
      }

      .app-button.link-text {
        font-size: $font-size-1 !important;
        line-height: $font-height-1 !important;
        margin-bottom: 4px;

        &.hidden {
          visibility: hidden;
        }
      }
    }

    .input-error-field {
      label {
        color: $red-darker !important;
      }

      .ui.input {

        input,
        input:focus {
          background: $red-lighter !important;
          border: 1px solid $red-darker;
          border-radius: 1px;
        }
      }

      margin-bottom: 4px;
    }

    .input-error-text {
      @include body-reg-01;
      color: $red-darker;
      margin-bottom: 14px;
    }

    .field:not(.input-error-field) {
      &+.input-error-text {
        margin-top: -10px;
      }
    }
  }

  .login-form-description {
    color: $gray-dark;
    margin-bottom: $spacing-xl;
  }

  .message-box {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: $spacing-xl $spacing-xxl $spacing-xl $spacing-l;
    margin-bottom: $spacing-m;

    .message-box-text {
      @include body-reg-02;
      flex: 1;
      margin-left: $spacing-xl;
      margin-top: -1px;
    }

    &.success-box {
      background: $evergreen-extralight;
      color: $evergreen-dark;

      .message-box-text {
        color: $evergreen-dark;
      }
    }

    &.error-box {
      background: $red-light;
      color: $red-darker;

      .message-box-text {
        color: $red-darker;
      }
    }
  }

  .app-button.link-text {
    height: auto;
    padding: 0 !important;
    background: transparent !important;
    color: $gray-darker;

    &:hover {
      color: $blue-hover;
      outline: none;
    }
    &:focus {
      color: $blue-pressed;
    }
  }

  .link-button {
    @include body-reg-02;
    background: transparent;
    border: none;
    padding: 0;
    text-decoration: underline;
    cursor: pointer;
  }

  .request-another-link {
    margin-top: $spacing-xl;
  }

  .password-validation-container {
    margin-top: $spacing-s;
    margin-bottom: $spacing-xl;
  }
}

// Page specific overrides

.app-login.login-page,
.ao-sign-up.login-page {
  .login-form-container {
    height: 550px;
    padding: $layout-xxl;

    .login-form-body {
      margin-top: $spacing-xl;
    }

    .login-form-description {
      color: $gray-darker;
    }
  }
}

.reset-password.login-page {
  .confirm-password {
    margin-top: $spacing-xl;
  }
}

.sign-up.login-page {
  .confirm-password {
    margin-top: $spacing-xl;
  }
}

.change-password-modal-wrapper {
  width: 100%;
  height: 100%;
  background: rgba(30, 37, 51, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1002;
  display: flex;
  align-items: center;
  justify-content: center;

  .change-password-modal {
    width: 550px;
    background: $white;
    box-shadow: 1px 3px 3px 0px rgba(0, 0, 0, 0.2), 1px 3px 15px 2px rgba(0, 0, 0, 0.2);

    .login-form-container {
      display: flex;
      flex-direction: column;
      padding: $layout-l;
      width: auto;
      margin: 0;

      .login-form-header {
        display: flex;
        flex-direction: row;

        i.icon {
          cursor: pointer;
        }
      }

      .login-form-footer {
        justify-content: flex-end;

        button:not(:last-child) {
          margin-right: $spacing-s;
        }

        .cancel-button {
         &:hover {
           background-color: $gray-lighter !important;
         }
         &:active {
           background-color: $gray-light !important;
         }
        }
      }
    }
  }
}
