@import "../../Design/tokens";
@import "../../Design/globalClasses";
@import "../../Design/classes";

.buckets-container {
  display: flex;
  flex-direction: row;
  padding: 24px 24px 0px 24px;
  height: 165px;

  .bucket {
    text-align: left;
    text-decoration: none;
    outline: none;
    border: none; // removes border coming with button styling but applies proper borders below
    background-color: $white;
    color: $gray-medium;
    flex: 1 1;
    border-left: 1px solid $gray-lighter-border;
    border-bottom: 1px solid $gray-lighter-border;
    padding: 1rem 1.5rem;

    &.all {
      flex: 2 1;
    }

    &.all.active {
      margin-right: 2px;
    }

    &.active {
      transform: scale3d(1.02, 1.09, 1) translate3d(2px, 3px, 1px);
      box-shadow: $box-shadow-reg;
      border-radius: 10px;
      border-left: none;

      .primary {
        color: $primary;
      }
    }

    .bucket-title {
      @include body-reg-03;
      text-align: left;
      color: $gray-medium;
    }

    .bucket-transaction-count {
      @include body-semi-01();
      font-size: 28px;
      padding-top: 8px;
    }

    .bucket-text {
      @include body-semi-01();
      color: $gray-medium;
      padding-top: 6px;
      padding-bottom: 22px;
    }
  }

  .bucket.active:hover {
    background: $white;
  }

  .bucket:hover {
    cursor: pointer;
    background: #f2f2f2;
    border-radius: 10px;
  }

  .bucket:first-child {
    border-left: none;
  }

  .empty_bucket {
    border: none;
    pointer-events: none;
  }
}
