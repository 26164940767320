@import '../../Design/tokens';

.remove-pharmacy-modal {

  .remove-pharmacy-modal-container {
    .remove-pharmacy-header-container {
      justify-content: space-between;

      button {
        margin-right: 0;
        padding-top: 12px;
        padding-right: 13px;
      }

      .remove-pharmacy-header {
        padding-left: $spacing-xl;
        padding-top: $spacing-xl;
      }

    }

    .remove-pharmacy-modal-message {
      padding-top: 20px;
      padding-left: $spacing-xl;
      padding-right: $spacing-xl;
    }

    .remove-pharmacy-modal-dropdown-container {
      margin: 24px 24px 0 24px;
    }

  }

  .remove-pharmacy-btn-container {
    justify-content: flex-end;
    padding-top: $spacing-xxl;
    padding-bottom: 26px;

    .app-button.button-red {
      margin-right: $spacing-xl;
      margin-left: $spacing-xs;
    }
  }
}
