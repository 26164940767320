.data-popup {
  &.popup-container {
    max-width: 400px !important;
    word-break: break-word;
    word-wrap: break-word;

    &.small {
      max-width: 170px !important;
    }
  }

  &.content {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
