@import '../../Design/tokens';

.account-dimmer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  background: $gray-darker;
  opacity: 0.5;
  transition: ease-in;
}

.account-drawer {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 1001;
  width: 320px;
  background: $bg-gray;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  letter-spacing: 0.25px;

  /* workaround as transition portal set display to block */
  &.visible.transition {
    display: flex !important;
  }

  .account-panel {

    .account-panel-header {
      padding: $spacing-l;
      justify-content: space-between;
      border-bottom: 1px solid $primary-light;

      .account-panel-header-icon {

        svg {
          height: 20px;
          width: 20px;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .account-panel-body {

      .app-button.account-panel-link {
        font-size: $font-size-1 !important;
        line-height: $font-height-1 !important;
        font-weight: 600;
        height: auto;
        padding: $spacing-l !important;
        background: transparent;
        color: $gray-darker;
        justify-content: flex-start;

        &:hover {
          background: $gray-lighter
        }

        &:active {
          background: $gray-light;
        }

        &:focus {
          outline: none;
        }
      }

      .selected {
        background: $gray-light !important;
      }

    }

    .account-panel-footer {
      padding: $spacing-m;
    }
  }
}

.profile-settings-drawer {
  background: $white;
  width: 420px;
  max-width: 557px;
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  position: absolute;
  top: 0px;
  right: 320px;
  transition-timing-function: ease;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);

  &::-webkit-scrollbar {
    display: none;
  }
}
