@import './../Design/tokens';

.reports-monitoring {

  .coming-soon-temp-page {
    width: 480px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;

    .header {
      text-align: center;
    }

    .content {
      text-align: center;
    }

    img {
      margin-left: -200px;
      position: relative;
      min-height: 400px;
      left: 50%;
    }

    div.header {
      margin-bottom: 10px;
    }

  }

}