@import "./classes";
@import "./tokens";

// do not edit

.app-link {
  cursor: pointer;

  &.underline {
    text-decoration: underline;
  }

  &.blue-link {
    color: $blue-darker;
  }

  &.blue-link:hover {
    color: $blue-hover;
  }

  &.blue-link:active {
    color: $blue-pressed;
  }

  &.gray-link {
    color: $gray-darker;
  }

  &.gray-link:hover {
    color: $gray-medium;
  }

  &.gray-link:active {
    color: $gray-pressed;
  }
}

.app-menu {
  margin: 0 $layout-xxxl 0 $spacing-xxxl !important;

  .inactive-item {
    color: $gray-darker !important;
    font-weight: 600 !important;
  }

  .inactive-item:hover {
    color: $blue-darker !important;
  }

  .active.item {
    border-color: $blue-darker !important;
    color: $blue-darker !important;
  }
}

.app-header {
  background: $white;
  height: 60px;
  font-weight: 600 !important;
  min-height: 60px;
  border-bottom: 1px solid $gray-light;
  display: flex;
  align-items: center;
  margin: 0 $layout-xxxl 0 $spacing-xxxl;
  @include body-reg-03;
  font-size: $font-size-4;

  &.app-menu-header {
    border-bottom: none;
  }

  .arrow-icon {
    cursor: pointer;
    margin-right: $spacing-s;

    i.small.icon,
    i.small.icons {
      font-size: $font-size-3;
    }
  }
}

.app-form {
  .field {
    margin-bottom: $spacing-m !important;
  }

  label {
    font-size: $font-size-1 !important;
    margin-bottom: $spacing-xxs !important;
  }

  label.default {
    color: $gray-darker !important;
  }

  input.default {
    border: 1px solid $primary-medium !important;
  }

  input {
    border-radius: 1px !important;
    height: 36px !important;
  }

  input:focus {
    border: 1px solid $blue-dark !important;
    color: $gray-darker !important;
  }

  .field:disabled {
    label {
      color: $gray-medium-light !important;
    }

    input {
      border: 1px solid $gray-medium-light !important;
      color: $gray-medium-light !important;
    }
  }
}

.app-form.disabled.fields .field,
.ui.form .disabled.field,
.ui.form .field :disabled {
  opacity: 1 !important;
}

.app-form-error {
  @include body-reg-01;
  color: $red-darker !important;
  margin-top: $spacing-xxs;
}

.form-error-label {
  color: $red-darker !important;
}

.form-error-input {
  border: 1px solid $red-darker !important;
  background: $red-lighter !important;
}

.app-input {
  font-family: "Averta", "Averta-Regular" !important;
  font-size: $font-size-2 !important;
  letter-spacing: 0.25px;
  line-height: 20px !important;
  font-weight: 400;
  color: #1e2533 !important;
}

.app-button {
  height: 36px;
  border: none;
  width: auto;
  cursor: pointer;
  border-radius: 1px;
  padding: 0 $spacing-l !important;
  font-weight: 600 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: 0.25px !important;
  font-family: 'Averta-Regular', 'Averta' !important;
  font-style: normal !important;

  &.mini-button {
    height: 32px;
    padding: 0 $spacing-xs !important;
  }

  &.cta {
    background: $blue-darker;
    color: $white;
  }

  &.button-dark {
    background: $gray-darker;
    color: $white;
  }

  &.button-white {
    background: $white !important;
    color: $gray-darker;
    border: 1px solid $gray-medium;
  }

  &.button-red {
    background: $red-darker !important;
    color: $white;
  }

  &.ghost {
    background: transparent !important;
    color: $gray-darker;
  }

}

.app-button.cta:focus {
  background: $blue-hover;
  outline: none;
  color: $white;
  text-decoration: underline;
}

.app-button.cta:hover:enabled {
  background: $blue-hover;
  color: $white;
}

.app-button.cta:active:enabled {
  background: $blue-pressed;
  text-decoration: none;
  color: $white;
  outline: none;
}

.app-button.cta:disabled {
  background: $gray-light;
  color: $gray-medium-light;
}

.app-button.button-dark:focus {
  background: $gray-darker;
  color: $white;
  outline: none;
  text-decoration: underline;
}

.app-button.button-dark:hover:enabled {
  background: $gray-dark;
  color: $white;
}

.app-button.button-dark:active:enabled {
  background: $gray-pressed;
  text-decoration: none;
  color: $white;
  outline: none;
}

.app-button.button-dark:disabled {
  background: $gray-light;
  color: $gray-medium-light;
}

.app-button.button-white:disabled {
  color: $gray-medium-light;
  border: 1px solid $gray-light;
}

.app-button.button-white:focus {
  background: $white;
  border: 1px solid $blue-darker;
  outline: none;
  text-decoration: underline;
}

.app-button.button-white:hover:enabled {
  background: $gray-lighter !important;
  color: $gray-darker !important;
}

.app-button.button-white:active:enabled {
  background: $gray-light;
  border: 1px solid $gray-medium;
  text-decoration: none;
  outline: none;
}

.app-button.button-red:disabled {
  background: $gray-light !important;
  color: $gray-medium-light;
}

.app-button.button-red:focus {
  background: $red-darker !important;
  color: $white;
}

.app-button.button-red:hover:enabled {
  background: #993736 !important;
  color: $white;
}

.app-button.ghost:hover {
  background: #EBEBEB !important;
}


.app-button.ghost:focus {
  background: #FFFFFF !important;
}

.app-button.ghost:active:enabled {
  background: #D6D6D6 !important;
  text-decoration: none;
}

.app-button:disabled {
  cursor: default;
}

.button-text {
  font-family: "Averta", "Averta-Regular" !important;
  height: 20px;
  border: none !important;
  font-size: $font-size-2 !important;
  font-weight: 600 !important;
  padding: 0 !important;
  color: $gray-dark !important;
  background: transparent;
}

.button-text:hover {
  color: $blue-hover !important;
  cursor: pointer;
}

.button-text:active {
  color: $blue-pressed !important;
}

.button-text:focus {
  border: 1px solid $blue-darker !important;
}

.button-text:disabled {
  color: $gray-medium-light !important;
}
