@import '../../../Design/tokens';
@import '../../../Design/classes';

.error-image {
  margin-bottom: 100px;

  img {
    margin-top: 96px;
    position: relative;
    left: calc(50% - 200px);
  }

  .error-image-header {
    @include header-sans-semi-01;
    text-align: center;
  }

  .error-image-message-container {
    display: flex;
    justify-content: center;

    p {
      @include body-reg-02;
      max-width: 515px;
      text-align: center;
    }
  }

  .error-image-link-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}