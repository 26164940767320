@import '../../../../Design/tokens';
@import '../../../../Design/classes';

.drawer {
  .drawer-header {
    border-bottom: 1px solid $gray-lighter-border;
    flex-shrink: 0;

    .navigation-buttons-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      padding: 8px 8px 0 8px;

      .navigation-button {
        border: none;
        background: transparent;
        cursor: pointer;
        padding: 8px;

        &.disabled {
          color: $gray-medium;
          cursor: not-allowed;
        }
      }
    }

    .header-text {
      display: flex;
      justify-content: space-between;
      margin: 0 16px 0 24px;

      .value {
        margin-bottom: 4px;
        @include header-sans-semi-01;
        max-width: 260px;
      }

      .value-header {
        font-size: $font-size-2;
        font-weight: 400;
      }
    }

    .attribute-label {
      margin: 0 16px 8px 24px;
    }
  }
}
