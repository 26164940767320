@import './../Design/tokens';

.toast-container {
  z-index: 9999;
  position: absolute;
  padding: 4px;
  min-width: 440px;
  max-width: 650px;
  box-sizing: border-box;
  bottom: 5%;
  right: 5%; // width of right nav bar
}

.toast {
  position: relative;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 1rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  max-height: 400px;
  overflow: hidden;
  border-radius: 1px;

  .ui.message>.close.icon {
    z-index: 1;
    top: auto
  }

  .ui.message>.content {
    margin-right: 48px;
  }

  &.info {
    background-color: #DFF0FF;
  }

  &.error {
    background-color: $red-light;
  }

  &.success {
    background: $gray-darker;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 1px;

    >.ui.message {
      font-size: $font-size-2;
      line-height: 18px;
      letter-spacing: 0.25px;
      color: $white;
      font-weight: 600;
    }
  }
}
