@import '../../../Design/classes';
@import '../../../Design/tokens';

.mfr-review {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0 !important;

  .mfr-review-header {
    background-color: rgba(220, 246, 245, 0.35); // $evergreen-extralight with opacity
    position: relative;

    .banner-icon {
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
    }

    .mfr-review-page-header {
      height: 65px;
      min-height: 65px;
      padding-top: 14px;
      padding-left: 32px;
    }

    .mfr-review-summary {
      display: flex;
      flex-direction: row;
      margin: 0px 0px 14px 24px;
  
      div {
        @include body-reg-01;
        padding: 0 8px;
        white-space: nowrap;
  
        span.value {
          font-weight: 600;
          padding-left: 3px;
        }
      }
  
      div:not(:last-child) {
        border-right: 1px solid $gray-lighter-border;
      }
    }
  }

  .mfr-review-body {
    padding: 24px;
    position: relative;

    .mfr-review-table {
      font-size: $font-size-1;

      .mfr-review-table-paging-menu {
        background: $bg-gray;
        border-bottom: 1px solid $gray-light;

        .pagination-controls {
          font-size: $font-size-1;
        }

        i {
          font-size: $font-size-1;
        }
      }

      .mfr-review-advanced-filters {
        background: $bg-gray;
        border-top: none;
      }

      thead {
        th {
          border-bottom: 1px solid $gray-lighter !important;
          height: 48px;

          &.status-header {
            min-width: 140px;
          }
        }
      }

      tr {
        td {
          height: 48px;
          max-width: 160px;
          overflow: hidden;
          text-overflow: ellipsis;

          &.dispute-code-cell {
            padding-top: 9px;
          }
        }
      }

      .mfr-review-table-with-action-container {
        padding-right: 135px;
      }

      .mfr-review-action-table {
        width: 136px !important;

        th {
          height: 48px !important;
          padding: 0 12px;
        }

        td {
          background-color: $bg-gray;
          overflow: visible;
        }

        .action-text {
          color: $gray-medium-light; 
        }

        .action-menu {
          width: 128px;

          .action-inline-menu-text {
            @include body-reg-01;
  
            &:hover {
              background: $gray-lighter;
            }
          }
        }
        
        .ui.dropdown .menu {
          left: -8px;
        }

        .transaction-select-container {
          margin: 0 12px;

          .status-text {
            &.green {
              color: #006B66
            }

            &.red {
              color: $red-darker;
            }
          }

          .loading-bar {
            background-color: $bg-gray;
          }
        }
      }
    }

    .mfr-review-body-loader {
      top: 400px !important;
      position: absolute;
      z-index: 1;
    }

    .mfr-review-filters {
      margin-bottom: 24px;
      width: 90%;

      label {
        @include body-reg-01;
        font-weight: 600;
      }

      .field {
        min-width: 175px;
        padding-left: 12px;
        padding-right: 12px;
        width: 25%;
      }

      .mfr-review-filters-button-container {
        align-items: flex-end;
        display: flex;
        padding-left: 12px;
      }
    }
  }
}

.mfr-review-footer {
  box-shadow: $box-shadow-reg;
  display: flex;
  height: 80px;
  justify-content: space-between;
  min-height: 80px;
  padding: 0 24px;
  position: relative;
  z-index: 1;

  .mfr-review-footer-left-container {
    display: flex;
    flex-direction: row;

    .header {
      @include header-sans-semi-01;
      align-items: center;
      display: flex;
      padding-right: 36px;
    }

    .total-container {
      padding-right: 31px;
      padding-top: 19px;
      min-width: 138px;
      max-width: 165px;

      .total-value {
        @include body-reg-02;
        font-weight: 600;
        padding-bottom: 3px;
      }
  
      .total-label {
        @include body-reg-01;
        color: $gray-medium;
      }
    }
  }

  .mfr-review-footer-right-container {
    display: flex;
    align-items: center;
  }
}
