@import '../../Design/tokens';

.close-button {
  border: none;
  background: transparent;
  cursor: pointer;
}

.back-button {
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;

  .back-icon {
    margin-right: 4px;
  }
}
