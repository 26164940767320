@import '../../Design/tokens';
@import '../../Design/classes';

.onboarding-layout-subbody {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payment-body-text {
  margin-top: 45px;
  width: 468px;
  height: 260px;
}

.add-bank-payment-account-text {
  font-size: $font-size-4;
  line-height: $font-height-4;
  font-weight: 600;
  color: $primary;
  padding-top: 15px;
  height: 50px;
  text-align: center;
}

.bank-account-column {
  padding-top: 35px;
  padding-left: 45px;
}

.bank-account-column-second {
  padding-top: 35px;
  padding-right: 45px;
}

.bank-account-icon {
  padding-right: 10px;
  padding-top: 2px;
}

.add-bank-payment-text1 {
  font-size: $font-size-3;
  line-height: $font-height-3;
  letter-spacing: 0.25px;
  font-weight: normal;
  color: $primary;
}

.add-bank-payment-text2 {
  font-size: $font-size-2;
  line-height: $font-height-2;
  font-weight: normal;
  color: $primary-medium;
}
// payment success screen
.payment-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 75px;
  @include body-reg-03;

  .details {
    width: 570px;
  }
}
