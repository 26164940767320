@import '../../Design/tokens';

.modal-close-x-icon {
  padding-right: $spacing-s;
  padding-top: $spacing-s;
  height: 32px;
  outline: none;
  background: transparent;
  border: none;
}

.error-modal {

  .error-modal-container {
    .modal-close-x-icon {
      cursor: pointer;

      svg {
        float: right;
      }
    }

    .error-modal-header {
      padding-top: 24px;
      text-align: center;
    }

    .error-modal-message {
      text-align: center;
      padding-top: 8px;
    }

    .error-modal-close-btn {
      margin-left: auto;
      width: 88px;
      margin-top: 24px;
      margin-bottom: 24px;
      margin-right: 24px;
    }
  }


}


.informational-modal {

  .informational-modal-container {
    height: 251px;

    button {
      margin-right: 0;
      padding-top: 12px;
      padding-right: 13px;
    }

    .informational-modal-header-container {
      justify-content: space-between;
      height: 76px;

      .informational-modal-header {
        padding-left: $spacing-xl;
        padding-top: $spacing-xl;
      }


    }

    .informational-modal-body {
      height: 180px;

      .informational-modal-message {
        padding-top: 24px;
        font-weight: 600;
        padding-left: $spacing-xl;
        padding-right: $spacing-xl;
      }

      .informational-modal-close-btn {
        margin-left: auto;
        margin-top: 34px;
        margin-right: 22px;
      }


    }

  }
}

.app-modal-btn-container {
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-top: $spacing-xl;

  .app-modal-submit-button {
    margin-right: 0 !important;
  }

  .app-modal-cancel-button {
    margin-right: $spacing-s;
  }
}

.app-modal {
  padding: $spacing-xl;
  flex-direction: column;

  &.visible.transition {
    display: flex !important;
  }

  .app-modal-header {
    @include body-reg-03;
    font-weight: 600;
    margin-bottom: $spacing-m;
    position: relative;
  }

  .modal-error-message {
    margin-bottom: $spacing-m;
    @include body-reg-03;
  }

  .app-modal-content {
    padding: $spacing-m 0;
    position: relative;
    overflow-y: auto;
    flex-grow: 1;

    &.disable-user-modal {
      padding-top: 0;
    }
  }

  .app-modal-btn-container {
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-top: $spacing-xxl;

    .app-modal-submit-button {
      margin-right: 0 !important;
    }

    .app-modal-cancel-button {
      margin-right: $spacing-m;
    }
  }

  &.small-modal {
    width: 380px;
  }

  &.medium-modal {
    width: 420px;
  }

  .modal-loader:before {
    border: 0.2em solid rgba(0, 0, 0, 0.1) !important;
  }



}
