@import '../../../Design/tokens';
@import '../../../Design/classes';

.terms-and-conditions {

  .terms-body {
    flex: 1;
    padding-bottom: $spacing-xxl;

    textarea {
      height: 100%;
      width: 100%;
      resize: none;
      padding: 26px;
      font-family: $font-family-sans-reg;
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-1;
      line-height: $font-height-1;
      letter-spacing: 0.25px;
      color: #000000;
    }
  }

  .terms-input {
    padding-bottom: $spacing-s;

    .onboarding-checkbox {
      padding-left: 0;
      padding-bottom: $spacing-xl;

      a {
        color: $gray-medium;
        text-decoration: underline;
        font-weight: 600;
      }
    }
  }
}
