@import './../Design/tokens';

.left-navigation {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  background: $gray-darker;
  color: $white;
  font-size: $font-size-1;
  line-height: $font-height-1;
  letter-spacing: 0.25px;
  width: $app-nav-collapsed;
  transition: width 200ms cubic-bezier(.4, 0, .2, 1);
  height: 100%;
  z-index: 1001;

  &.expanded {
    width: $app-nav-expanded;
  }

  &.collapsed {

    .navigation-text,
    .discount-type-text {
      display: none;
    }

    .sub-navigation {

      .sub-navigation-link {
        .sub-navigation-icon {
          display: none;
        }

        &.active {
          background: $nav-highlight;
          opacity: 1;

          a {
            opacity: 1;
          }
        }
      }

      .sub-navigation-children {
        display: none;
      }
    }
  }

  a {
    color: $white;
  }

  .left-navigation-header {
    padding: $spacing-l 0 $spacing-xl 30px;
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 2;

    .nubbin {
      position: absolute;
      right: -7px;
      width: 36px;
      text-align: right;
      cursor: pointer;
    }
  }

  .left-navigation-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    .navigation-section {

      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-thumb {
        background: #3B445A;
        border-left: 2px solid $gray-darker;
        border-right: 2px solid $gray-darker;
      }
    }
  }

  .left-navigation-footer {
    padding-top: 24px;
    white-space: nowrap;

    .left-navigation-footer-admin-button {
      background: #1BDFC7;
      border-radius: none;
      color: $gray-darker;
      height: 60px;
      margin-bottom: 24px;
      width: 100%;      
    }

    .left-navigation-footer-admin-banner {
      background: #1BDFC7;
      color: $gray-darker;
      height: 60px;
      margin-bottom: 24px;
      width: 100%;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;      
    }

    .left-navigation-footer-logo {
      margin: 0 $spacing-xl $spacing-l $spacing-xl;

      &.margin-bottom {
      margin-bottom: 48px;
      }
    }

    .left-navigation-footer-icon {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-bottom: $layout-xl;
    }

    .left-navigation-footer-links {
      display: flex;
      flex-direction: column;
      border-top: 1px solid $gray-medium;
      margin: 0 $spacing-xl;
      padding: $spacing-xl 0 $spacing-xl 0;

      a {
        color: $gray-medium-light;
        text-decoration: none !important;
      }

      a:hover {
        color: $white;
      }

      a:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .transition {
      opacity: 0;
      transition: opacity 800ms;

      &.visible {
        opacity: 1;
      }
    }
  }

  //Navigation Links

  .navigation-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
    opacity: 0.75;
    height: 60px;

    .navigation-text {
      margin-left: -2rem;
      overflow: hidden;
    }

    .navigation-icon {
      display: flex;
      flex-direction: row;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 60px;
    }

    &:hover {
      opacity: 1;
    }
  }

  .root-navigation {
    &.active {
      background: $nav-highlight;

      a {
        opacity: 1;
      }
    }

    a.active {
      background: $nav-highlight;
      opacity: 1;
    }
  }

  .sub-navigation {

    .sub-navigation-link {

      &:hover {
        cursor: pointer;
      }

      &.active {
        background: #3B445A;

        &.root-active {
          background: $nav-highlight;
          opacity: 1;

          a.active {
            background: $nav-highlight;
            opacity: 1;
          }
        }
      }

      .sub-navigation-icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 60px;
      }
    }

    .sub-navigation-parent {
      display: flex;
      flex-direction: row;

      .navigation-item {
        overflow: hidden;
        flex-grow: 1;
      }
    }

    .sub-navigation-children {

      &.collapsed {
        display: none;
      }

      .sub-navigation-item {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 12px 0 14px 72px;
        opacity: 0.75;
        height: 40px;

        &.active {
          background-color: $nav-highlight;
          opacity: 1;
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  //switch HS styling

  .switch-healthsys-button {
    color: white !important;
    display: flex !important;
    background: $gray-dark !important;
    height: 68px;
    align-items: center;
    box-sizing: border-box;
    margin: 0 $spacing-m $spacing-xl $spacing-m !important;
    left: 0px;
    top: 0px;
    box-shadow: $box-shadow-reg !important;
    border-radius: 10px !important;
    text-align: left !important;
    font-family: "Averta";
    font-size: 12px !important;
    line-height: 16px;
    overflow: hidden;

    .transition {
      opacity: 0;
      transition: opacity 800ms;

      &.visible {
        opacity: 1;
      }
    }

    .team-text {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
    }

    .switch-team-container {
      max-width: 140px;

      .switch-team-label {
        font-weight: normal !important;
        margin-bottom: 0px;
      }
    }
  }
}