@import '../../../Design/tokens';
@import '../../../Design/classes';

.error-banner {
  background: $red-light;
  color: $red-darker;
  font-size: $font-size-2;
  line-height: $font-height-2;
  padding: $spacing-m $spacing-l;
  display: flex;
  flex-direction: row;

  a {
    color: $red-darker;
    text-decoration: underline;
  }

  .banner-elements {
    display: flex;
    align-items: center;
  }

  .title {
    font-family: $font-family-sans-semi;
  }

  .contents {
    font-weight: 400;
  }

  .icon-wrapper {
    margin-right: $spacing-m;
  }
}
