@import '../../Design/tokens';

.filter-dropdown {
  .ui.multiple.selection.dropdown {
    background: $white;
    font-family: $font-family-sans-reg;
    font-size: $font-size-1;
    letter-spacing: 0.25px;
    line-height: $font-height-1;
    height: 34px;

    .default.text {
      color: $gray-medium-light;
    }

    &.applied {
      background: $gray-dark;

      .default.text {
        color: $gray-lighter;
        font-weight: 600;
      }

      .dropdown.icon {
        color: $gray-lighter;
      }
    }

    .ui.label {
      @include body-reg-02;
      cursor: default;
      background-color: $gray-dark;
      border-radius: 4px;
      border: none;
      height: 26px;
      box-shadow: none;
      margin-left: 0.64285714em;
      padding: 3px 9px 3px 6px;
      display: flex;
      flex-direction: row;

      i {
        padding-left: 10px;
      }
    }

    &.search {
      input[type=text].search {
        width: 100%;
      }

      &.applied {
        input[type=text].search {
          background: $gray-dark;
          color: $gray-lighter;
          font-weight: 600;
        }
      }
    }

    &.primary-filter {
      @include body-reg-02;
      align-items: center;
      display: flex;
      height: 36px;

      .dropdown.icon {
        padding: 11px 10px;
      }

      .item {
        font-size: $font-size-2;
        height: 36px;
        padding: 8px 16px !important;
      }

      .item:hover:not(.selected),
      .item.active {
        color: $gray-darker;
        background: $gray-lighter;
        font-weight: 400;
      }

      .selected,
      .selected.item {
        font-weight: 400;
        border-radius: 0;
        margin-left: 0;
        margin-right: 0;
        padding-right: 8px !important;
      }
      
      &.applied {
        background: $white;

        .dropdown.icon {
          color: $gray-darker;
        }

        .default.text {
          color: $gray-lighter;
          font-weight: 400;
          width: max-content;
        }
  
        .ui.label {
          margin-left: 5px;
  
          .clear-icon-container {
            align-items: center;
            display: flex;
            justify-content: flex-end;
            margin-left: 8px;
            width: 16px;
  
            svg {
              cursor: pointer;
            }
          }
        }
      }
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 1;

      .menu {
        border: 1px solid rgba(221,221,221, 0.45);
      }

      .item:not(.selected) {
        color: rgba(30, 37, 51, 0.45);
      }

      .menu .selected.item {
        background: rgba(66, 75, 94, 0.45);
      }

      .dropdown.icon {
        color: rgba(30, 37, 51, 0.45);
      }

      ::placeholder {
        color: rgba(186 ,193, 207, 0.45);
      }

      &.search {
        border: 1px solid rgba(221, 221, 221, 0.45);

        &.applied {
          background: rgba(66, 75, 94, 0.45);
        }
      }

      &.primary-filter {
        &.applied {
          background: $white;

          .ui.label {
            background: rgba(66, 75, 94, 0.45);
          }
        }
      }
    }
  }

  .ui.dropdown .menu {
    will-change: auto;

    .item {
      font-family: $font-family-sans-reg;
      font-size: $font-size-1;
      letter-spacing: 0.25px;
      line-height: $font-height-1;
    }

    .item:hover,
    .item.active {
      color: $gray-darker;
      background: #D6D6D6;
      font-weight: 600;
    }
  }

  .ui.dropdown.selected,
  .ui.dropdown .menu .selected.item {
    background: $gray-dark;
    color: $gray-lighter;
    font-weight: 600;
    border-radius: 4px;
    margin-left: 3px;
    margin-right: 3px;
  }

  .item.select-all {
    font-style: italic;
  }
}

.filter-textbox {
  .ui.input {
    background: $white;
    color: $gray-darker;
    font-family: $font-family-sans-reg;
    font-size: $font-size-1;
    letter-spacing: 0.25px;
    line-height: $font-height-1;
    height: 34px;
    position: relative;

    &.active {
      input[type=text] {
        background: $gray-dark;
        color: $gray-lighter;
        font-weight: 600;
      }

      input[type=text]:focus {
        background: $white;
        color: $gray-darker;
        font-weight: normal;
      }
    }

    .overlay {
      position: absolute;
      left: 2px;
      right: 2px;
      top: 2px;
      bottom: 2px;
      pointer-events: none;
      background: $gray-dark;
      color: $gray-lighter;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .summary {
        margin: 0 1em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

    }

    ::placeholder,
    input:focus::placeholder {
      color: $gray-medium-light
    }
  }
}

.filter-toggle-button-container {
  align-items: center;
  display: flex;

  .filter-toggle-button {
    color: $primary;
    margin: 0;
    padding: 8px 16px;

    &:hover,
    &:focus,
    &.open {
      color: $primary;
      background: #DCDFE6;
    }

    &:active {
      background: $gray-medium-light;
    }

    svg {
      margin-right: 6px;
    }
  }

  .filter-clear-button {
    margin: 0;
    padding: 8px 16px;
    box-shadow: none;
    border: none;

    svg {
      margin-left: 3px;
      margin-right: 0px;
    }

    .filter-clear-button-text {
      display: flex;
      align-items: center;
    }

    &:hover {
      color: $primary;
      background: #DCDFE6;
    }

    &:active {
      background: $gray-medium-light;
    }
  }
}

.popup.filter-summary {
  padding: 16px;

  .filter-tooltip {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    font-family: $font-family-sans-reg;
    font-size: $font-size-1;
    letter-spacing: 0.25px;
    line-height: $font-height-1;

    .filter-tooltip-col:not(:first-child) {
      padding-left: 14px;
    }

    .filter-tooltip-col:not(:last-child) {
      padding-right: 14px;
      border-right: 1px solid $gray-light;
    }

    .filter-tooltip-header {
      font-weight: 600;
    }

    .filter-tooltip-value {
      margin-top: 4px;
    }
  }
}

.filter-date-picker-group {
  .filter-date-picker.field {

    .DateInput {
      // override react-dates width
      width: 104px;
    }

    &:not(:first-child) {
      padding-left: 0px;
    }

    &:not(:last-child) {
      padding-right: 0px;
    }

    >.field {
      min-width: 115px;
      width: 100%;
    }

    .ui.icon.input input {
      width: 100%;
      padding-right: 30px !important;
    }

    .calendar.link.icon {
      width: 30px;
    }

    input {
      background: $white;
      color: $gray-darker;
      font-family: $font-family-sans-reg;
      font-size: $font-size-1;
      letter-spacing: 0.25px;
      line-height: $font-height-1;
    }

    input:not([value=""]) {
      background: $gray-dark;
      color: $gray-lighter;
      font-weight: 600;

      &+.close.icon {
        color: $gray-lighter;
        opacity: 1;
      }
    }

    &.disabled {
      cursor: not-allowed;

      input:not([value=""]) {
        background: rgba(66, 75, 94, 0.45);
        font-style: normal;
      }

      ::placeholder {
        color: rgba(186 ,193, 207, 0.45);
        font-style: normal;
      }
    }
  }
}
