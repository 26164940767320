.disputes-center {
  padding: 0 !important;

  .disputes-center-header {
    background-color: rgba(220, 246, 245, 0.35); // $evergreen-extralight with opacity
    padding: 0 $spacing-xxl;
    position: relative;

    .banner-icon {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .disputes-center-body {
    padding: 24px 24px 28px 24px;

    .disputes-center-cards-container {
      width: 715px;
    }
  }
}
