@import '../../Design/tokens';
@import '../../Design/classes';

.info-banner {
  background: $golden-extralight;
  color: $golden-dark;
  font-size: $font-size-2;
  line-height: $font-height-2;
  padding: $spacing-m $spacing-l;
  display: flex;
  flex-direction: row;

  a {
    color: $golden-dark;
    text-decoration: underline;
  }

  .banner-contents {
    display: flex;
    align-items: center;
  }

  .title {
    font-family: 'Averta-Bold';
  }

  .contents {
    font-weight: 400;
  }

  .icon {
    margin-right: $spacing-m;
    font-size: $font-size-4;
  }

  .link {
    color: $golden-dark;
    font-weight: bold;
    text-decoration: underline;
  }

  .icon-wrapper {
    margin-right: $spacing-m;
  }
}
