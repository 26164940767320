@import './../Design/tokens';

.payer-drawer {
  position: fixed;
  right: 0;
  top: 0;
  background: $white;
  height: 100%;
  z-index: 1001;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  width: 410px;
  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column;

  //work around as transion portal is set to display block
  &.visible.transition {
    display: flex !important;
  }

  .payer-drawer-header {
    padding: 1em 20px;
    background: $white;
    border-bottom: 1px solid $gray-lighter-border;
    letter-spacing: 0.25px;

    .nav-button-container {
      width: 60px;
      padding-bottom: 1.5rem;
      margin-left: -8px;

      span:hover {
        cursor: pointer;
      }

    }
  }

  .payer-drawer-details-reason-column-outside {
    width: 94.6%;
    background: rgb(185, 185, 185);
    clip-path: polygon(3% 0%, -11% 100%, 100% 100%, 100% 0%);
    height: 50px;
    color: $primary-medium;
    font-size: $font-size-1;
    line-height: $font-height-1;
    height: 50px;
    margin-top: $spacing-xs;

    .payer-drawer-details-reason-column-inside {
      width: 99.3%;
      margin-left: auto;
      margin-right: auto;
      background: #F4F4F4;
      position: relative;
      top: 1px;
      left: 0px;
      height: 48px;
      clip-path: polygon(3% 0%, -11% 100%, 100% 100%, 100% 0%);
      display: flex;
      flex-direction: row;

      .adjust-column {
        width: 95%;
        max-width: 95%;
      }

      div.eta {
        padding-top: 7px;
        padding-left: 7px;
        font-weight: 600;
        color: black;
      }

      div.zeta {
        padding-left: 7px;
        color: black;
        font-weight: normal;
      }
    }
  }

  .payer-drawer-reject-reasons {
    width: 410px;
    height: 48px;
    background: $red-light;
    border-radius: 1px;
    margin-bottom: 2px;

    .failure-msg-row {
      padding-left: $spacing-xl;
      padding-top: $spacing-xs;

      div.zeta {
        color: $gray-darker;
      }

      div.eta {
        color: $gray-darker;
        font-weight: normal;
      }

    }
  }

  .payer-drawer-status-container {
    justify-content: space-between;

    .payer-drawer-status-approved {
      width: 65px;
      height: 20px;
      background: $evergreen-extralight;
      border: 1px solid $evergreen-extralight;
      border-radius: 3px;
      color: $evergreen-dark;
      font-family: Averta;
      font-style: normal;
      font-weight: 600;
      font-size: $font-size-1;
      line-height: $font-height-1;
      text-align: center;
      letter-spacing: 0.25px;
    }

    .payer-drawer-status-failed {
      width: 47px;
      height: 20px;
      font-family: Averta;
      font-style: normal;
      font-weight: 600;
      font-size: $font-size-1;
      line-height: $font-height-1;
      text-align: center;
      letter-spacing: 0.25px;
      background: $red-light;
      border: 1px solid $red-medium;
      border-radius: 3px;
      color: $red-darker;

    }

    .payer-drawer-status-reversed {
      width: 61px;
      height: 20px;
      background: $primary-medium;
      border: 1px solid $primary-medium;
      border-radius: 3px;
      font-family: Averta;
      font-style: normal;
      font-weight: 600;
      font-size: $font-size-1;
      line-height: $font-height-1;
      text-align: center;
      letter-spacing: 0.25px;
      color: $white;
    }

    .payer-drawer-status-pending {
      width: 109px;
      height: 20px;
      background: $primary-extra-light;
      border: 1px solid $primary-light;
      border-radius: 3px;
      font-family: Averta;
      font-style: normal;
      font-weight: 600;
      font-size: $font-size-1;
      line-height: $font-height-1;
      text-align: center;
      letter-spacing: 0.25px;
      color: $primary-medium;
    }
  }

  .payer-drawer-drawer-details {
    width: 363px;
    height: auto;
    margin: 24px 0px 0px 24px;

    .paid-row {
      padding-bottom: 16px;
      border-bottom: 1px solid #c1c3c7;
    }

    .details-row {
      height: auto;
      padding-top: $spacing-m;
    }

    .details-column {
      display: flex;
      justify-content: space-between;
    }

    .multiple-details-column {

      div.payer-details-fields-column {
        margin-bottom: $spacing-m;
        padding-top: 5px;
      }

      div.contractIdContainer:hover {
        background: $gray-light;
        max-height: 49px;
        border-radius: 4px;
        margin: 0 0 0 -8px;
        padding: 0px 0px 4px 8px;
      }

    }

    .payer-details-fields-column {
      width: 174px;
      height: 40px;

      &.adj-amount {
        color: #8A909C;
      }

      &.auto-adj {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          border-style: solid;
          border-width: 10px 10px 0px 0px;
          top: 0px;
          left: -8px;
          border-color: #9c9c9c transparent transparent transparent;
        }
      }

      &.manual-adj {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          border-style: solid;
          border-width: 10px 10px 0px 0px;
          top: 0px;
          left: -8px;
          border-color: $primary transparent transparent transparent;
        }
      }

      &.validation {
        width: 100%;
        height: auto;

        &:first-child {
          padding-top: 16px;
        }
      }
    }

    .payer-details-fields-column-title {
      padding-top: 3px;
    }

    .linked-contract {
      display: flex;

      .arrow-icon {
        visibility: hidden;
        color: $blue-darker;
        display: flex;
        align-items: center;
        // hack until drawer redesign
        margin-left: -16px;
        margin-right: 8px;
      }

      &:hover {
        color: $primary;

        .arrow-icon {
          visibility: visible;
        }
      }

      &.without-popup {

        margin: 8px 0 0 -8px;
        padding: 8px 0px 4px 8px;

        &:hover {
          background-color: $gray-light;
          border-radius: 4px;
        }
      }
    }
  }

  .payer-drawer-drawer-details-reversal {
    width: 363px;
    margin: 34px 0px 0px 24px;

    .details-row {
      height: auto;
    }

    .details-row-multiple {
      padding-top: 16px;
    }

    .reversal-details-column {
      display: flex;
      justify-content: space-between;
    }

    .reversal-details-right-column {
      display: flex;
      height: 48.5%;
      justify-content: space-between;
      margin-top: 163px;
      margin-bottom: auto;
    }

    .payer-details-fields-column {
      width: 174px;
      height: 40px;
    }
  }

  .payer-drawer-additional-fields {
    padding-top: 13px;

    .view {
      font-weight: 600 !important;
    }

    .view i {
      margin-left: 8px;
      margin-top: 1px;
    }

    .additional-row {
      display: flex;
      flex-wrap: wrap;

      .payer-details-fields-column {
        margin-top: 16px;
      }

      .payer-details-fields-column-title {
        padding-top: 3px;
      }

    }

  }

  .payer-drawer-sub-menu-content {
    padding-top: 16px;
    margin-top: 16px;
    border-top: 1px solid $primary-light;
  }

  .payer-drawer-sub-content {
    margin-bottom: 125px;
  }

  .payer-double-drawer {

    .payer-double-drawer-outside {
      &.drawer-open {
        height: 100vh;
        position: fixed;
        right: 820px;
        top: 0px;
        background-color: $white;
        border-radius: 8px 0px 0px 8px;
        background: 1px solid black;
        width: 16px;
      }

      &.drawer-closed {
        height: 100vh;
        position: fixed;
        right: 410px;
        top: 0px;
        background: $white;
        width: 16px;
        border-radius: 8px 0px 0px 8px;
        box-shadow: inset -8px 0px 8px -9px rgba(0, 0, 0, 0.25);
      }

      .payer-double-drawer-outside-filler {
        position: relative;
        z-index: 100;
        top: -158px;
        left: 20px;
        height: 140%;
        width: 6px;
        background-color: $white;
      }

      .payer-double-drawer-outside-box-shadow {
        height: 100vh;
        width: 0px;
        box-shadow: -3px 0 8px 1px rgba(0, 0, 0, .25);
      }

      .payer-double-drawer-outside-icon-container {
        height: 54px;
        width: 15px;
        position: absolute;
        top: 50%;
        right: 21px;

        i.dd-icon {
          position: relative;
          top: -95px;
          left: -4px;
          color: #C4C4C4;
          font-size: 33px;
        }

        svg:first-child {
          position: relative;
          top: -47px;
          right: 13px;
        }

        svg:last-child {
          position: relative;
          top: -213px;
          right: -11px;
        }
      }
    }

    .payer-double-drawer-content {
      position: fixed;
      right: 410px;
      top: 0;
      background: $white;
      display: flex;
      height: 100%;
      z-index: 1001;
      overflow-x: hidden;
      width: 410px;
      flex-direction: column;
      box-shadow: inset -8px 0px 8px -9px rgba(0, 0, 0, 0.25);
      overflow: scroll;

      .transaction-id-container {
        padding-left: 24px;
      }

      .ui.divider {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
      }

      div.information-container {
        margin-left: 24px;
        margin-top: 58px;
      }

      div.first-row {
        margin-top: 38px;
        padding-left: 5px;
        padding-right: 5px;

      }

      div.divider {
        margin-right: 1rem;
      }

      div.sub-row {
        padding-left: 5px;
        padding-right: 5px;
      }

      .payer-drawer-status-pending,
      .payer-drawer-status-reversed,
      .payer-drawer-status-approved {
        margin-top: 66px;
        margin-right: 10px;
      }

      .payer-double-drawer-sub-content {
        padding-left: 24px;
        padding-right: 24px;
        margin-bottom: 125px;
        margin: 0 !important;
      }

      .payer-double-drawer-go-to-link {
        text-align: left !important;
        margin-top: auto !important;
        margin-bottom: 16px !important;

        svg {
          margin-left: 10px;
          position: relative;
          top: 2px;
        }
      }

      .payer-double-drawer-additional-fields {
        padding-top: 13px;
        padding-left: 24px;

        .view {
          font-weight: 600 !important;
        }

        .view i {
          margin-left: 8px;
          margin-top: 1px;
        }

        .additional-row {
          display: flex;
          flex-wrap: wrap;
          width: 350px;

          .payer-details-fields-column {
            margin-top: 16px;
          }

          .payer-details-fields-column-title {
            padding-top: 3px;
          }

        }
      }
    }
  }
}