@import "../../Design/tokens";
@import "../../Design/classes";

.tab-controller {
  display: flex;
  flex-direction: column;

  &.fit-height {
    height: 100%;
  }

  &.divided {
    .ui.secondary.pointing.menu {
      border-bottom: 1px solid $gray-light;
    }
  }

  .ui.secondary.pointing.menu {
    padding: 0 2rem 1px 2rem;
    margin: 0;
    border-bottom: none;

    .item {
      border-bottom: 2px solid transparent;
    }

    .item.active {
      color: $primary;
      border-bottom: 2px solid $primary;
      z-index: 1;
      pointer-events: none;
    }

    .item.disabled{
      pointer-events: none;
      opacity: .5;
    }
  }

  .ui.segment.tab {
    flex: 1;
    box-shadow: none;
    margin: 0;
    padding: 0;
  }
}