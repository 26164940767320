@import '../../Design/tokens';
@import '../../Design/classes';

.team-management-container {
  background: $white;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden !important;
  overflow-x: auto !important;

  .tab-container {
    flex: 1;
    .divided .ui.secondary.pointing.menu {
      margin: 0 28px 0 28px;
      padding: 0;
    }
  }
  .team-mgmt-header.page-header {
    margin-left: 28px;
  }
}

.edit-user-modal {
  height: 75vh;
  .extension-field {
    width: 102px;
    margin-left: $spacing-s !important;
  }
}
.disable-user-button {
  width: 100px;
  position: absolute;
  left: 0;
  color: #ae403d !important;
  padding: 0.78571429em 0 !important;
  font-size: 12px !important;
  line-height: 16px !important;
}
.team-management-page {
  background: $white;
  padding: 0 2rem 2rem;


  .tm-table-container {

    .table-header-menu {
      .ui.button:hover {
        &.btn-text {
        color: $gray-darker;
        }
      }
      .app-button {
        margin-left: $spacing-m;
      }
    }


    tbody {
      @include body-reg-01;
      display: block;
      overflow: auto !important;
      height: calc(100vh - 280px);
    }
    tbody tr {
      display: table;
      table-layout: fixed;
      width: 100%;
    }
    thead{
      @include body-reg-01;
      display: table;
      table-layout: fixed;
      width:calc(100% - 12px);
    }
    
    tr {
      height: 48px;
      border-bottom: 1px solid $gray-lighter-border;

      td {
        padding: 6px 12px;
      }

      th {
        padding: 33px 12px 16px 12px;
      }

      &.tm-users {
        td {
          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 4px;
          }
        }
      }

      &.tm-pending {
        td {
          color: $gray-medium-light;
        }
      }
      &.tm-disabled-user {
        td {
          color: $gray-medium-light;
        }
        i.icon{
          &.caret{
          color: $gray-darker;
          }
        }
      }

      &.tm-sub-row {
        background: $bg-gray;
  
        td {
          padding: 22px 12px 23px 12px;

          &:first-child {
            padding-left: 29px;
          }

          &:last-child {
            padding-right: 23px;
          }
        }

        &.inactive {
          height: 84px;
        }

        .detail-group {
          align-items: flex-start;
          display: flex;
          flex-direction: row;
        }
  
        .header {
          font-weight: 600;
          padding-bottom: 8px;
        }

        .sub-row-button-cell {
          display: flex;
          justify-content: flex-end;
        }

        &.disabled-user {
          td {
            color: $gray-medium;
          }
        }
      }

      td.no-wrap {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .tm-edit-cell {
        display: flex;
        justify-content: center;

        .edit-cell-button {
          display: none !important;
        }
      }

      .tm-name-cell {
        display: flex;
        flex-direction: row;
        align-items: center;

        .icon-container {
          height: 18px;
          width: 30px;

          svg {
            color: $gray-darker;
            margin-right: 12px;
          }
        }
      }

      .tm-name-header {
        padding-left: 29px;
      }

      .tm-role-cell {
        position: relative;

        svg {
          color: $gray-darker;
          left: -9px;
          position: absolute;
          top: 17px;
        }
      }

      &:hover {
        background: $gray-lighter;

        .edit-cell-button {
          display: flex !important;
        }
      }
    }
  }

  .tm-page-no-data {
    flex: 1;
    display: flex;
    justify-content: center;
    margin-top: 150px;

    .ui.message {
      text-align: center;
      width: 515px;

      .content {
        margin-top: 10px;
      }
    }
  }

  .tm-busy-loader {
    position: absolute;
    top: 350px
  }
}

.team-management-modal {
  display: flex;
  flex-direction: column;
  padding: $spacing-xl;

  &.visible.transition {
    display: flex !important; // semantic...
  }

  .team-management-modal-header {
    @include body-reg-03;
    font-weight: 600;
  }

  .team-management-modal-body {
    margin: $spacing-xl 0;
    flex: 1 1 auto;
  }

  .team-management-modal-footer {
    display: flex;
    justify-content: flex-end;

    button:not(:last-child) {
      margin-right: $spacing-s;
    }
  }

  .ui.form {
    .fields {
      margin-bottom: 4px;
    }
  }

  .team-management-modal-input-error {
    label {
      color: $red-darker !important;
    }

    .ui.input {

      input,
      input:focus {
        background: $red-lighter !important;
        border: 1px solid $red-darker;
        border-radius: 1px;
      }
    }
  }

  .team-management-modal-error-text {
    position: absolute;
    color: $red-darker;
    font-family: $font-family-sans-reg;
    font-size: $font-size-1;
    letter-spacing: 0.25px;
    line-height: $font-height-1;
    font-weight: 400;
    margin-left: 2px;

    &.no-margin {
      margin-top: -10px;
    }
  }

  .error-banner {
    margin-top: $spacing-m;
  }

}

.team-management-help-tooltip {
  @include body-reg-01;
  margin-bottom: $spacing-m;
  display: flex;
  justify-content: flex-end;
  margin: 22px 16px 22px 0;

  &.content {
    max-width: 485px;
    padding: 28px 24px 16px 24px;
    border-radius: 0;
  }

  .close {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
  }

  a {
    color: $blue-dark;
    text-decoration: underline;
  }

  button {
    border: none;
    background: transparent;
    @include body-semi-02;
    color: $gray-darker;
    cursor: pointer;

    &:hover {
      color: $blue-darker;
    }

    &:focus {
      color: $blue-pressed;
    }
  }

  .app-modal{
    &.medium-modal{
      height: 200px !important;
  }
  }
}

.team-management-address {
  &.popup-container {
    max-width: 400px !important;
    word-break: break-word;
    word-wrap: break-word;
  }

  &.container {
    padding-left: 40px;
    max-width: 75%;
  }

  &.content {
    overflow: hidden;
    padding-bottom: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.no-padding {
      padding-bottom: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}
.teamManagement-footer {
  z-index: 1;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  box-shadow: $box-shadow-reg;
  position: relative;
  .footer-button {
    margin: 22px 16px 0 0;
    &:hover {
      color: $gray-darker;
    }
    .plusIcon {
      margin: 5px 5px 0 0;
    }
  }
}

.team-management-popup {
  &.popup-container {
    max-width: 400px !important;
    word-break: break-word;
    word-wrap: break-word;
  }

  &.content {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.team-management-role-popup {
  min-width: 418px !important;
  width: 418px !important;
}
