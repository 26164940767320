@import "../../Design/classes.scss";
@import "../../Design/tokens.scss";

.idle-timer-modal {
  .idle-timer-modal-container {
    padding: $spacing-xl;

    .idle-timer-modal-header {
      margin-bottom: $spacing-m;
    }
    .idle-timer-modal-body {
      line-height: 20px;
      letter-spacing: .25px;
    }
    .idle-timer-modal-button-container {
      display: flex;
      float: right;
      margin: $spacing-xl 0;
      .idle-timer-modal-signout-button {
        margin-right: $spacing-m;
      }
    }
  }
}
