@import '../../Design//tokens';

.invoice-status-icons {

  .icon-container {
    width: 24px;
    height: 24px;
    border-radius: 13px;

    &.processed-paid {
      background: $white;

      .invoicestatus-icon {
        margin-top: 2px;
        border: solid 2px $primary-medium;
        height: 20px;
        width: 20px;
        color: $primary-medium;
        border-radius: 30px;
        margin-left: 2px;
        font-size: $font-size-1;
      }
    }

    &.overdue {
      background: $red-light;

      .invoicestatus-icon {
        color: $red-darker !important;
        font-size: $font-size-3;
        margin-top: 2.5px;
        margin-left: 1.5px;
      }
    }

    &.processing {
      background: $golden-extralight;

      .invoicestatus-icon {
        margin-left: 1.5px;
        margin-top: 3px;
        font-size: $font-size-3;
        color: $golden-dark;
      }
    }

  }


  .invoice-status {
    align-self: center;
    margin-left: $spacing-xs;

    &.partially-processed{
      white-space: normal;
      color: $golden-dark;
    }

  }

}

.invoice-status-icons.summary-icon {
  border-right: 1px solid $gray-lighter-border;
  padding-right: $spacing-xs;
  padding-top: 5px;

}
