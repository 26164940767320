@import '../../Design/tokens';


.basic-table-wrapper {

  .body-content.action-btn {

    button {
      &.remove-act-btn {
        height: 32px;
        background: $white;
        padding: 0;
        border: none;
        font-size: 12px;
        width: 72px;
        color: $red-darker;
        cursor: pointer;
        font-weight: 600;
      }

      &.remove-act-btn:hover {
        background: #EBEBEB;
        border-radius: 1px;
      }

      &.remove-act-btn:focus {
        background: #D6D6D6;
        border-radius: 1px;
        outline: none;
      }

      &.verify-now-btn:hover {
        background: #EBEBEB;

      }

      &.verify-now-btn:focus {
        background: #D6D6D6;
        outline: none !important;
      }

      &.verify-now-btn {
        background: $white;
        border-radius: 1px;
        margin-right: $spacing-m;
        border: none !important;
        width: 96px;
        height: 32px;
        padding: 2px;
        color: $gray-darker;
        font-weight: 600;
        font-size: $font-size-1;
        line-height: $font-height-1;
        cursor: pointer;
      }
    }
  }


}


.transfer-funds-modal {
  width: 555px;
  position: absolute;
  top: 18vh;
  height: auto;
  left: 50%;
  margin-left: -278px;
  z-index: 2;
  background-color: $white;

  &-outside-modal {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    background: rgba(30, 37, 51, 0.5);
    position: fixed;
    left: 0;
    z-index: 10;
    top: 0;
  }

  &-multiple {
    height: 291px;

    .header-row {
      justify-content: space-between;
      height: 76px;

      .add-funds-header {
        padding-left: $spacing-xl;
        padding-top: $spacing-xl;
        padding-bottom: $spacing-xl;
      }

    }

    .add-funds-dropdown-container {
      height: 129px;

      .selected-bank-dropdown {
        position: relative;
        top: -34px;
        width: 80%;
        left: 42px;
      }

      .selected-bank-dropdown {
        .dropdown-account-data {
          visibility: hidden;
          padding-right: 8px;
        }
      }

      .ui.dropdown .menu>.item {
        color: $gray-darker !important;
      }

      .ui.dropdown .menu>.item:hover {
        background: #EDEFF1;
      }

      .ui.dropdown.selected,
      .ui.dropdown .menu .selected.item {
        color: $gray-darker;
        font-weight: normal;
      }


      .add-funds-label {
        padding-left: $spacing-xl;
        padding-top: $spacing-m;
        padding-bottom: $spacing-m;
      }

      .ui.active.visible.dropdown.funding-sources-dropdown {
        .default.text {
          visibility: hidden;
        }

      }

      .funding-sources-dropdown {
        width: 91%;
        height: 44px;
        border: 1px solid $gray-medium;
        border-radius: 1px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: space-between;

        .text {
          padding-left: $spacing-m;
          padding-top: 9px;

        }

        .dropdown.icon {
          padding-top: 14px;
          margin-left: auto;
          padding-right: $spacing-m;
        }

        .visible.menu.transition {
          width: 100%;

          .text {
            padding-left: 0;
            padding-top: 0;

            .dropdown-bankName {
              margin-left: 8px;
            }

          }

        }
      }

    }

    .add-funds-btn-row {
      margin-top: auto;
      height: 86px;

      .add-funding-btn-container {
        padding-top: $spacing-m;
        width: 35%;
        margin-left: auto;

        .app-button.button-white {
          margin-right: $spacing-xs;
        }

      }

    }

  }

  &-single {
    height: auto;

    .header-row {
      justify-content: space-between;
      height: 76px;

      .add-funds-header {
        padding-left: $spacing-xl;
        padding-top: $spacing-xl;
        padding-bottom: $spacing-xl;
      }

    }

    .add-funds-account-info {
      height: 52px;
      background: $gray-lighter;

      .account-info {
        padding-left: 25px;
        padding-top: $spacing-m;
        font-weight: 600;

        svg {
          margin-left: $spacing-xs;
          margin-top: 4px;
          margin-right: $spacing-xs;

          path {
            fill: $gray-darker;
          }

        }

      }

    }

    .add-funds-input-row {

      .main-label {
        padding-left: $spacing-xl;
        font-weight: 600;
        padding-top: $spacing-xl;
      }


      .add-funds-input {
        border: 1px solid #727C8F;
        border-radius: 1px;
        margin-top: 16px;
        margin-bottom: 36px;
        width: 91%;
        margin-left: auto;
        margin-right: auto;
        height: 44px;
        padding-left: 24px;

      }

      .input-funds-money-prefix {
        width: 25px;
        position: absolute;
        top: 246px;
        left: 40px;
      }

      .input-funds-money-prefix.disabled-prefix {
        color: $gray-medium-light;
      }

      .add-funds-input.disabled-input {
        background: $gray-light;
        color: $gray-medium-light;
      }

      .add-funds-input.error-input {
        background: $red-lighter;
        border: 1px solid $red-darker;
        border-radius: 1px;
      }

      .funding-amount-input-error {
        font-weight: normal;
        color: $red-darker;
        position: absolute;
        top: 280px;
        left: 24px;
      }

      .funds-btn-row {
        padding-left: $spacing-xl;
        padding-top: $spacing-m;

        .funds-btn {
          height: 33px;
          background: $blue-lighter;
          padding-left: 7.5px;
          margin-right: $spacing-m;
          padding-right: 7.5px;
          padding-top: 0;
          padding-bottom: 0;
          color: $gray-darker;
          border-radius: 100px;
          border: 1px solid $blue-darker;
        }

        .funds-btn.funds-btn-selected {
          background: $blue-hover !important;
          outline: none;
          color: $white;
          border: none !important;
        }

        .funds-btn.funds-btn-disabled {
          background: $gray-lighter-border;
          border: 1px solid $gray-medium;
        }


        .funds-btn:hover {
          background: $blue-hover;
          color: $white;
        }

        .add-funds-input.funds-input-selected {
          background: $gray-lighter;
          outline: none;
          color: $gray-darker;
        }


      }

    }

    .add-funds-confirm-row {
      height: 120px;
      background: $gray-lighter;

      .confirm-label {
        padding-top: $spacing-xxl;
        padding-left: 25px;
      }

      .confirm-italic-label {
        padding-top: $spacing-xxl;
        font-style: italic;
        padding-left: 160px;
        color: $gray-medium;
      }

      .confirm-checkbox-row {
        padding-left: 25px;
        padding-top: $spacing-xl;
        padding-right: $spacing-xs;

        .confirm-checkbox {
          margin-top: 3px;
        }

        .confirm-message {
          padding-left: $spacing-s;
          padding-bottom: 2px;
        }

        .confirm-message.deactivated {
          color: $gray-medium-light;
        }

        .confirm-message.active {
          color: $gray-darker;
        }

      }


    }

    .add-funds-btn-row {
      margin-top: auto;
      height: 86px;

      .add-funding-btn-container {
        padding-top: $spacing-xl;
        justify-content: flex-end;

        .app-button.button-white {
          margin-right: $spacing-xs;
        }

        .app-button.button-dark {
          margin-right: 24px;
        }

      }

    }


  }

}

.verify-micro-deposits-modal {
  width: 499px !important;

  .header-row {
    justify-content: space-between;

    .verify-funds-header {
      padding-top: $spacing-xl;
      padding-left: $spacing-xl;
    }

    .verify-close-btn {
      padding: 0;
      margin-right: 0;

      svg {
        margin-bottom: 35px;
      }

    }

  }

  .error-banner {
    width: 100%;
  }

  .verify-success-header {
    justify-content: flex-end;

    .verify-close-btn {
      padding-top: 12px;
      padding-right: 13px;
      margin-right: 0;
    }

  }

  .verify-instructions {
    padding-left: $spacing-xl;
    padding-top: $spacing-m;
    padding-right: $spacing-xl;
    padding-bottom: $spacing-xs;
  }

  .verify-deposit-success-column {
    padding-top: 40px;

    .verify-checkmark-container {
      height: 76px;
      width: 75px;
      background: $evergreen-extralight;
      border-radius: 50px;
      margin-left: auto;
      margin-right: auto;

      .verify-success-check {
        padding-left: 8px;
        font-size: 57px;
        color: $evergreen-dark;
      }

    }

    .verify-deposit-success-btn-row {
      display: flex;
      justify-content: flex-end;


      .app-button.button-dark {
        margin-right: $spacing-xl;
        margin-top: 46px;
        margin-bottom: $spacing-xl;
      }


    }

    .verify-success-header-content {
      margin-left: auto;
      margin-right: auto;
      padding-top: $spacing-s;
      padding-bottom: $spacing-xs;
      text-align: center;
    }

    .verify-success-header-message {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }

  }

  .verify-deposits-lower-column {

    .verify-deposit-input-row {
      width: 68%;
      height: auto;
      align-items: center;
      margin-top: $spacing-xl;
      justify-content: flex-end;
      display: flex;
      flex-direction: row;


      .verify-deposit-input {
        width: 83px;
      }

      .verify-deposit-input.not-touched {
        color: $gray-medium-light;
      }

      .verify-deposit-input:focus {
        color: $gray-darker
      }

      .verify-input-header {
        padding-right: $spacing-m;
      }

    }

    .verify-deposit-btn-row {
      display: flex;
      justify-content: flex-end;
      padding-top: 29px;
      padding-bottom: 24px;

      &.with-error {
        justify-content: space-between;
      }

      button {
        width: 140px;
        white-space: nowrap;
      }
    }

  }


  .verify-micro-deposit-error-content {
    padding: 0 !important;

    .verify-error-container {
      .verify-error-sub-container {
        padding: 12px 12px 24px 24px;

        .verify-error-header {
          justify-content: space-between;

          .verify-header {
            padding-top: 12px;
            padding-bottom: 16px;
          }

          .verify-close-btn {
            margin-bottom: auto;
            padding-right: 0;
            padding-top: 0;
          }

        }

        .verify-error-instructions {
          padding-right: 20px;
        }

        .verify-error-sub-instructions {
          font-weight: normal;
          padding-right: 85px;
          padding-top: 5px;
        }

        .verify-error-list {
          margin-top: 24px;
          padding-left: 29px;

          .item {
            padding-bottom: 16px;
          }

        }

        .verify-deposit-error-btn-row {
          display: flex;
          justify-content: flex-end;


          .app-button.button-dark {
            margin-right: $spacing-s;
            margin-top: 22px;
          }


        }



      }
    }


  }


}


.remove-funding-source-modal {

  .remove-funding-source-modal-container {
    .remove-funding-header-container {
      justify-content: space-between;

      button {
        margin-right: 0;
        padding-top: 12px;
        padding-right: 13px;
      }

      .remove-funding-header {
        padding-left: $spacing-xl;
        padding-top: $spacing-xl;
      }

    }

    .remove-funding-modal-message {
      padding-top: 40px;
      padding-left: $spacing-xl;
      padding-right: $spacing-xl;
    }

  }

  .remove-funding-btn-container {
    justify-content: flex-end;
    padding-top: $spacing-xxl;
    padding-bottom: 26px;

    .app-button.button-red {
      margin-right: $spacing-xl;
      margin-left: $spacing-xs;
    }

  }

}

.credits-info-banner {
  @include body-reg-02;
  background: $blue-lighter;
  padding: 24px 24px 24px 48px;
  min-width: 950px;
}

//for modal dimmer color
.ui.page.modals.dimmer.transition.visible.active {
  background-color: rgba(30, 37, 51, 0.5);
}
