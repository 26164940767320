@import '../../Design/tokens';

.table-header.basic {
  position: relative;

  &.active {
    background-color: $bg-gray;
  }

  .table-header-menu {
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 24px;

    &.justify-flex-end{
      justify-content: flex-end;
    }
  }

  .table-header-filter {
    position: absolute;
    top: 60px;
    width: 100%;
    background-color: $primary-extra-light;
    border-top: 1px solid $gray-lighter-border;
    box-shadow: 0px 15px 10px -10px rgba(0, 0, 0, 0.1);
    z-index: 2;

    .ui.form.filter-form {
      display: flex;
      flex-direction: row;
      min-height: 112px;

      .fields {
        margin-bottom: 16px;

        &.fields:last-child {
          margin-bottom: 0;
        }
      }
    }

    .filter-header {
      font-family: $font-family-sans-reg;
      font-size: $font-size-1;
      letter-spacing: 0.25px;
      line-height: $font-height-1;
      font-weight: 600;
      color: $primary-medium;
      margin-bottom: 4px;
      margin-top: -20px; // offset its height so input is still vertically centered
    }

    .filter-left-container {
      padding: 0 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;

      .filter-error-text {
        position: absolute;
        color: $red-darker;
        font-size: $font-size-1;
        letter-spacing: 0.25px;
        line-height: $font-height-1;
        margin: 4px 0 0 4px;
      }
    }

    .filter-left-container {
      min-width: 265px;
      border-right: 1px solid $gray-lighter-border;

      &.small {
        min-width: 241px;
      }
    }

    .filter-right-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 16px 0 16px 16px;
      position: relative;
      width: 80%;

      .field {
        padding-right: 16px;
        width: 50%;

        @media screen and (min-width: 1280px) {
          width: 33%;
        }
        
        @media screen and (min-width: 1410px) {
          width: 25%;
        }
      }
      
      &.small {
        .field {
          padding-right: 16px;
          width: 50%;

          @media screen and (min-width: 1410px) {
            width: 33%;
          }
          
          @media screen and (min-width: 1600px) {
            width: 25%;
          }
        }
      }
    }

    .DateRangePickerInput {
      background-color: $primary-extra-light;
    }
  }
}

.table-pagination-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  .paging-status {
    color: $primary-medium;
    padding-top: 3px;
    padding-right: $spacing-xs;
  }
}