@import '../../../Design/tokens';
@import '../../../Design/classes';

.associate-covered-entities {
  .heading {
    display: flex;
    justify-content: space-between;
    @include body-reg-01;
    color: $primary-medium;

    margin-left: 46px;
    margin-bottom: 16px;

    .ce {
      margin-right: 16px;
      text-align: right;
    }
  }

  .health-system-instruction {
    @include body-reg-03;
    margin-bottom: 30px;
  }

  .instructional {
    .title {
      @include body-reg-03;
    }

    .text {
      margin-top: 8px;
      margin-bottom: 16px;
      font-size: $font-size-2;
      line-height: $font-height-2;
      color: $primary-medium;
    }
  }

  .candidate-health-system {
    display: flex;
    align-items: center;

    &.read-only {
      opacity: .5;
    }
  }

  .candidate-health-system-text {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $gray-lighter-border;
    @include body-semi-02;
    color: $gray-darker;
    padding-top: 16px;
    padding-bottom: 16px;
    width: 100%;

    .ce {
      margin-right: 16px;
      text-align: right;
    }
  }

  .candidate-health-system-new {
    @include body-reg-02;
  }

  .candidate-radio {
    margin-right: 28px;
  }

  .health-system-name {
    margin-bottom: 24px;

    .label {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.25px;
      color: $gray-medium;
    }

    .value {
      background-color: $gray-lighter;
      padding: 8px 16px;
    }
  }

  .covered-entity-search {
    padding: 8px 0;
    background: $white;
    border: 1px solid $gray-lighter-border;
    max-height: 610px;
    overflow: auto;

    .covered-entity-row {
      padding: 8px 16px;

      &:hover:not(.disabled) {
        background: $gray-lighter;
        cursor: pointer;
      }

      label {
        display: flex;
        color: $gray-medium;
        font-family: $font-family-sans-semi;

        .ce-id {
          width: 140px;
          color: $primary;

        }

      }

      input:focus~label {
        color: $gray-medium;
      }

    }
  }

  .orphan-footer {
    color: $gray-medium;
    border-top: 1px dashed $gray-lighter-border;
    margin-top: 30px;
    padding-top: 32px;

    .description {
      margin-bottom: 16px;
    }

    .orphan-ces {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: $primary;

      .count {
        margin-right: 16px;
        text-align: right;
      }
    }
  }
}
