@import "../../../Design/tokens";
@import "../../../Design/classes";

.pharmacy-toggle-button {
  width: 64px;
  position: relative;
  z-index: 0;
  height: 22px;

  &.read-only {
    pointer-events: none;
    span {
      &.left {
        color: $gray-medium-light;

        &.selected {
          color: $gray-medium;
        }
      }

      &.right {
        color: $gray-medium-light;

        &.selected {
          color: $gray-medium;
        }
      }
    }

    .ui.toggle.checkbox .box:before,
    .ui.toggle.checkbox label:before {
      background-color: $gray-light !important;
    }

    .ui.toggle.checkbox input:checked~.box:before,
    .ui.toggle.checkbox input:checked~label:before {
      background-color: $gray-light !important;
    }


    .ui.toggle.checkbox input~.box:after,
    .ui.toggle.checkbox input~label:after {
      box-shadow: none;
    }

  }

  span {
    z-index: inherit;
    font-weight: 600;
    position: absolute;
    pointer-events: none;
    user-select: none;

    &.left {
      position: absolute;
      // &:hover{ // Saved this hover state for later
      //   color: $gray-dark;
      //   pointer-events: pointer;
      // }
      color: $gray-medium;
      left: 6px;
      font-size: 10px;
      top: 1.5px;
      z-index: 3; // z-index is 3 because toggle button circle is 2

      &.selected {
        color: $gray-dark;
      }
    }

    &.right {
      // &:hover{ // Saved this hover state for later
      //   color:$gray-dark;
      //   pointer-events: pointer;
      // }
      color: $gray-medium;
      right: 5px;
      font-size: 10px;
      top: 1.5px;
      z-index: 3; // z-index is 3 because toggle button circle is 2

      &.selected {
        color: $gray-dark;
      }
    }
  }

  .ui.toggle.checkbox.read-only {
    input {
      cursor: default;

      &.hidden+label {
        cursor: default;
      }
    }
  }

  .ui.toggle.checkbox label {
    padding-left: 50px;
  }

  .ui.toggle.checkbox,
  .ui.fitted.toggle.checkbox {
    width: 64px;
    height: 22px;
  }

  .ui.toggle.checkbox .box:before,
  .ui.toggle.checkbox label:before {
    background-color: $gray-light !important;
    height: 22px;
    width: 64px;
  }

  .ui.toggle.checkbox input:checked~.box:before,
  .ui.toggle.checkbox input:checked~label:before {
    background-color: $gray-light !important;
    width: 64px;
    height: 22px;
  }

  .ui.toggle.checkbox input~.box:after,
  .ui.toggle.checkbox input~label:after {
    background: $white;
    height: 18px;
    width: 30px;
    left: 2px;
    top: 2px;
    box-shadow: 1.5px 0px 1.5px rgba(66, 75, 94, 0.5);
  }

  .ui.toggle.checkbox input:checked~.box:after,
  .ui.toggle.checkbox input:checked~label:after {
    left: 32px;
  }
}
