//SPECIFIC STYLING FOR IE 10+
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */

.sort-icon-container {
    bottom: 5px !important;
    position: relative;

  .sort-caret-up{
    bottom: -1px !important;
  }

  .sort-caret-down{
    bottom: -4px !important;
  }

}


}
