@import "../../../Design/tokens";

.fake-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1002;
  background: rgba(30, 37, 51, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .ui.modal {
    display: block;
  }
}