@import '../../Design/tokens';

.request-form {
  position: fixed;
  top: 0;
  background: $white;
  height: 100%;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column;
  letter-spacing: 0.25px;

  /* workaround as transition portal set display to block */
  &.visible.transition {
    display: flex !important;
  }

  .request-form-header {
    min-height: 81px;
    padding: 0 1rem 0 3rem;
  }

  .request-form-footer {
    z-index: 1;
    min-height: 81px;
    padding: 0 2rem 0 3rem;
    background: $white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    .actions {
      align-self: flex-end;
    }

    .upload-another {
      margin-right: 1rem;
    }
  }

  .request-form-body {
    margin-top: 1rem;
  }

  .request-form-uneditable {
    flex: 1 0;
    padding-left: 3rem;
    border-right: 1px solid $primary-light;

    .uneditable-label {
      color: $primary-light;
    }

    .uneditable-field {
      margin-top: 15px;
    }
  }

  .request-form-details {
    flex: 4 0;

    .form-container {
      background: $white;
      overflow-y: auto;
      margin: 0px 28px 28px;

      .form-title {
        margin-bottom: 26px;

        &.correct {
          margin-bottom: 14px;
        }
      }

      .verify-row {
        max-width: 670px;
      }

      .form-row {
        display: flex;
        flex-flow: wrap;
        flex-direction: row;
        flex-grow: 1;
        max-width: 702px;
      }

      .form-column {
        display: flex;
        flex-direction: column;
        padding: 1rem 2rem 1rem 0;

        .field {
          width: 300px;
        }

        .field>label {
          font-size: $font-size-1;
          font-weight: 600;
        }

        .field:not(.error)>label {
          color: $primary-medium;
        }
        .discount-per-unit-field-correct-data-entry-tooltip {
          position: absolute;
          margin-top: 0.5px;
          margin-left: 10.5em;
        }
      }

      .discount-per-unit-checkbox {
        padding-right: 8px;
      }

      .field-info-row {
        flex-flow: row wrap;
        align-content: baseline;
        align-items: center;
        max-width: 800px;
      }
      .field-info-item {
        width: 200px;
        height: 80px;
        padding: 1rem 1rem 1rem 0px;
        .field-info-item-value {
          padding-bottom: 8px;
          overflow-wrap:break-word;
        }
        .field-info-item-text {
          color: $primary-medium;
          text-transform: uppercase;
          white-space: pre-wrap;
        }
      }

      .field-info-group {
        .field-info-group-title {
          padding: 1rem 0px;

          .ui.divider {
            margin: 0px 0px 0px 1rem;
          }
        }
      }

      .error-notification {
        background: $red-light;
        padding: 1rem;
        color: $red-darker;
        display: flex;
        flex-direction: row;

        .error-notification-message {
          margin-left: 8px;

          div {
            margin-top: 4px;

            &:first-child {
              margin-top: 8px;
            }
          }
        }
      }

      .request-form-date-field {

        &.has-error {
          input,
          input::placeholder,
          label {
            color: #EE3028 !important;
            border-color: #EE3028 !important;
          }
        }

      }

      .discount-per-unit-field {
        color: $primary-medium;
        font-size: $font-size-1;
        font-weight: 600;
        margin: 0em 0em 0.28571429rem 0em;

        &-tooltip {
          position: absolute;
          margin-left: 5px;
          margin-top: 0.5px;
        }
      }
    }

    .claim-type-field {
      padding-bottom: 12px;
    }
  }

  .request-form-success {
    text-align: center;

    .go-to-link {
      margin-top: 2rem;

      .icon {
        color: $primary-light;
      }

      &:hover {
        .icon {
          color: inherit;
        }
      }
    }
  }
}
