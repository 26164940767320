@import '../../../../Design/tokens';
@import '../../../../Design/classes';

.drawer {
  .drawer-item {
    padding-left: 8px;
    margin-bottom: 8px;
    display: flex;

    &.no-indicator {
      padding-left: 0;
      width: 165px;
      min-width: 165px;
      margin-bottom: 16px;
      margin-left: 8px;
    }

    &.drawer-item-row{
      width: 100%;

      .item-row{
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;

        .item-value{
          font-weight: 400;
          font-size: 14px;
        }

        .item-label{
          text-transform: none;
          align-self: center;
          font-size: 14px;
        }
      }
    }

    .item-value {
      width: 165px;
      min-width: 165px;
      @include body-reg-02;
      font-weight: 600;
      display: flex;
      overflow-wrap: anywhere;

      &.original {
        color: $gray-medium;
      }
    }

    .item-label {
      width: 165px;
      min-width: 165px;
      @include body-reg-01;
      color: $gray-medium;
      text-transform: uppercase;
    }

    .adjustment-indicator {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 8px 0 0;
      border-radius: 1;

      &.auto-adjusted {
        border-color: $primary-medium transparent transparent transparent;
      }

      &.manual-adjusted {
        border-color: $blue-darker transparent transparent transparent;
      }

    }
  }


}
