@import './../Design/tokens';

.remittance-filter-row {
  display: flex;
  flex-direction: row;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 24px;

  .covered-entity-filter {
    width: 25%;
    margin: auto;

    .title {
      padding-bottom: 8px;
    }

    .covered-entity-name {
      width: 40%;
    }

    .covered-entity-sub-row {
      display: flex;
      flex-direction: row;
      padding-top: 2px;
    }

    .covered-entity-item-label {
      font-weight: 400;
    }

    .covered-entity-item-first {
      padding-right: 8px;
      white-space: nowrap;
    }

    .covered-entity-sub-item-first {
      padding-right: 8px;
      white-space: nowrap;
    }

    .covered-entity-filter-dropdown {
      display: flex !important;
      flex-direction: row !important;
      justify-content: space-between;

      &.ui.scrolling.dropdown {
        padding-left: 16px;
        padding-right: 16px;

        .dropdown.icon {
          top: 20px;
          font-size: 16px;
        }
      }

      &.ui.scrolling.dropdown .menu {
        width: calc(100% + 2px) !important;

        .selected.item {
          background: #EDEFF1;
          color: inherit;
        }

        .item:hover {
          background: #EDEFF1;
          color: inherit;
        }

        .item {
          border-top: 1px solid $gray-lighter-border;
        }
      }

      div.text {
        width: 100%;
      }

      i.chevron {
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }

  .manufacturer-filter {
    width: 70%;
    margin: auto;
    display: flex;
    flex-direction: column;

    .title {
      padding-bottom: 8px;
    }

    .manufacturer-name {
      width: 40%;
    }

    .manufacturer-sub-row {
      display: flex;
      flex-direction: row;
      padding-top: 2px;
    }

    .manufacturer-item-label {
      font-weight: 400;
    }

    .manufacturer-item-first {
      padding-right: 8px;
      white-space: nowrap;
    }

    .manufacturer-name {
      align-self: center;
    }

    .manufacturer-sub-item {
      white-space: nowrap;
    }



    .manufacturer-item {
      width: 27%;
      border-left: 1px solid $gray-lighter-border;
      padding-right: 12px;
      white-space: nowrap;
      padding-left: 12px;
      align-self: center;
      color: $gray-medium;
    }

    .manufacturer-item-no-left-border {
      width: 27%;
      padding-right: 12px;
      white-space: nowrap;
      align-self: center;
      padding-left: 12px;
      color: $gray-medium;
    }

    .manufacturer-item-value {
      padding-bottom: 8px;
    }

    .manufacturer-item-text {
      color: $primary-medium;
    }

    .manufacturer-filter-dropdown {
      display: flex !important;
      flex-direction: row !important;
      justify-content: space-between;
      height: 67px;

      &.ui.scrolling.dropdown {
        padding-left: 16px;
        padding-right: 16px;

        .dropdown.icon {
          top: 20px;
          font-size: 16px;
        }
      }


      &.ui.scrolling.dropdown .menu {
        width: calc(100% + 2px) !important;

        .selected.item {
          background: #EDEFF1;
          color: inherit;
        }

        .item:hover {
          background: #EDEFF1;
          color: inherit;
        }

        .item {
          border-top: 1px solid $gray-lighter-border;
        }
      }

      div.text {
        width: 100%;
      }

      i.chevron {
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 0 !important;
        margin-left: 12px;
      }
    }


  }
}

@media only screen and (max-width: 1165px) {
  .remittance-filter-row {
    flex-direction: column;

    .manufacturer-filter {
      width: auto;
      margin: 8px 20px 0 20px;
    }

    .covered-entity-filter {
      margin: 0 0 0 20px;
      width: 45%;
    }

  }

}
