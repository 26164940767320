@import '../../../../Design/tokens';
@import '../../../../Design/classes';

.health-system-info-modal-trigger {
  text-decoration: underline;
  color: $primary;
  font-family: $font-family-sans-reg;
  border: none;
  background: transparent;
  cursor: pointer;
}

.health-system-info-modal {
  display: flex;
  flex-direction: column;
  color: $primary;

  .heading {
    @include header-sans-reg-01;
    margin-bottom: 24px;
  }

  .description {
    @include body-reg-02;
    margin-bottom: 24px;

    &.bolder {
      @include body-semi-02;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
  }
}
