@import "../Design/classes.scss";
@import "../Design/tokens.scss";


.switch-hs-modal {
  height: 75vh;
  max-height: 914px;
  padding: 0 0 24px 0 !important;

  .app-modal-header {
    margin-top: 24px;
  }

  .app-modal-header,
  .app-modal-btn-container,
  .app-modal-content {
    padding: 0 24px;
  }

  .onboarding-info {
    background: $blue-light;
    padding: 12px 24px;

    button {
      @include body-reg-02;
      border: none;
      background: none;
      padding: 0;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: $cobalt-medium;
      }
    }
  }

  .sub-header {
    @include body-reg-02;
  }

  .team-list-top {
    font-size: 12px;
    line-height: 16px;
    height: 22px;
    color: #84888f;
    border-bottom: 0.5px solid $primary-light;
    padding: 0 12px 4px 12px;
    justify-content: space-between;

  }

  .team-list-wrapper {
    flex-grow: 1;
    height: calc(100% - 22px);
    overflow-y: auto;

    .team-list-row {
      cursor: pointer;
      padding-top: 8px;

      .content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 18px;
        line-height: 28px;
        height: 64px;
        color: $gray-darker;
        padding: 4px 0;

        &.light-blue-background {
          background: $cobalt-extralight;
        }

        .team-name {
          max-width: 80%;
          margin-top: auto;
          margin-bottom: 18px !important;
          margin-left: 12px;

          .team-onboarding{
            font-size: 14px;
            color: $primary-medium
          }
        }

        Button {
          float: right;
          padding: 0;
          color: $cobalt-medium;
          margin-right: 12px;
          margin-bottom: 6px;
        }
      }
    }

    .middle-small {
      padding: 8px 16px;

      .content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 12px;
        line-height: 16px;
        height: auto;
        color: $gray-darker;
        padding: 4px 0;

        .first {
          width: 70%;
          display: inline-block;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .second {
          float: right;
        }
      }
    }
  }

  .hs-search-input {
    padding: 0 24px 24px 24px;

    .ui.selection.dropdown {
      background: $gray-lighter;

      .default.text {
        color: $gray-darker;
      }
    }

    .hs-search-text {
      display: flex;
      flex-direction: row;
      margin-top: 12px;

      .ui.input {
        flex: 1;
        margin-right: 16px;
      }
    }

  }

  .no-search-results{
    font-size: 18px;
    line-height: 28px;
    padding: 24px;
  }
}