@import '../../Design/tokens';

.multi-step-form {
  position: fixed;
  top: 0;
  background: $white;
  height: 100%;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column;
  letter-spacing: 0.25px;

  .close-button {
    border: none;
    background-color: $white;
    cursor: pointer;
  }

  .multi-step-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }

  &-header {
    min-height: 80px;
    margin: 0 16px 0 24px;
    justify-content: center;
    border-bottom: 1px solid $gray-light;
  }

  &-footer {
    z-index: 1;
    min-height: 80px;
    padding: 24px;
    background: $white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    display: flex;
    justify-content: flex-end;
    height: 60px;

    &.with-back {
      .back-button {
        background: transparent; // doesn't do anything on mac os, needed for windows

        .back-icon {
          display: flex;
          align-items: center;
          margin-right: 8px;
        }
      }

      justify-content: space-between;
    }
  }

  &-body {
    display: flex;
    flex: 1;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar; // fixing overflow in IE11
    padding: 24px;
  }

  &-step {
    flex: 1 0;
    padding-left: 3rem;
    border-right: 1px solid $primary-light;
  }

  &-error {
    padding: 24px;
  }

  &-details {
    flex: 4 0;
  }

  &-left {
    border-right: 1px solid $primary-light;
    width: 304px;
  }

  &-right {
    padding: 0 0 0 24px;
    width: 80%;

    .header {
      margin-bottom: 24px;
    }
  }

  .fields {
    margin: 0em 0em 1em !important; // override semantic
  }

  // define exact widths for fields as disabled fields don't allow you to scroll within the field
  .date-field {
    .field .field .ui.input {
      width: 131px !important; // override semantic
      padding-left: 0 !important; // override semantic
      padding-right: 0 !important; // override semantic
    }
  }

  .default-field {
    width: 262px;
    padding-left: 0 !important; // override semantic
    padding-right: 0 !important; // override semantic
    margin-right: 24px !important; // override semantic
  }

  .description-field {
    width: 547px;
  }

  .attribute-field {
    width: auto;
  }

  .attribute {
    margin: 8px 0 8px 0;

    &.horizontal {
      margin-right: 24px;
      width: 166px;
      overflow-wrap: break-word;
    }

    .label {
      color: $gray-medium;
    }

    .value {
      color: $primary;
      font-family: $font-family-sans-semi;
    }
  }

  .disabled.field {
    opacity: 1 !important;

    label {
      opacity: 1 !important;
    }

  }

  .ui.disabled.input {
    opacity: 1 !important;

    input {
      background-color: $primary-extra-light;
      color: $primary;
    }
  }

  .success {
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;

    .success-icon {
      margin-top: 8px;
    }

    &-footer {
      .add-another {
        margin-right: 16px;
      }
    }

    .go-to-link {
      margin-top: 24px;
    }
  }
}

/* workaround as transition portal set display to block */
.multi-step-form.visible.transition {
  display: flex !important;
}
