@import "../../Design/tokens";

.recipient-payments-center {
  height: 100%;
  background: $white;
  display: flex;
  flex-direction: column;

  .tab-controller {
    &.divided {
      .ui.secondary.pointing.menu {
        margin: 0 2rem 0 2rem;
        padding: 0;
      }
    }
  }

  .recipient-payments-center-page-header.page-header {
    background: $white;
    border-bottom: none;
    margin-left: 28px;
  }

  .recipient-payment-body {
    max-width: 960px;
    min-width: 590px;
  }

  .basic-table-wrapper.recipient-payments {
    .title-row {
      justify-content: space-between;
    }

    .basic-table {
      thead {
        th {
          font-weight: 600;
          font-size: $font-size-1;
          line-height: $font-height-1;
          color: $gray-darker;
          white-space: nowrap;
        }

        th.amount-header {
          text-align: right;
        }
      }

      tr {
        td {
          height: 48px;
        }

        .md-verification-pending {
          font-weight: 600;
          font-style: italic;
          white-space: nowrap;
        }

        td.action-btn {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }

      .semi-bold {
        font-weight: 600;
      }
    }
  }
}

.recipient-payments-history {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $layout-m $layout-m;

  .sub-menu-container {
    background: $white;
    height: $layout-l;

    .sub-menu-pagination-container {
      margin-top: $spacing-none;
      margin-right: $spacing-none;
    }
  }

  .failed-transactions {
    background-color: $red-lighter;

    .failed-content {
      color: $red-darker;

      .text-content {
        width: 120px;
      }

      .notification {
        margin-left: 5px;
      }
    }
  }

  .remittance-link {
    font-family: "Averta-Semibold";
  }
}

.recipient-payment-settings {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $layout-m $layout-m;

  .banner {
    margin-bottom: $layout-l;
  }

  .basic-table {
    margin-top: $layout-xl;
  }

  .error-banner {
    margin-bottom: 24px;
  }

  .linked-accounts-header {
    justify-content: space-between;
  }

  .add-account-tooltip {
    color: $primary-light;
    font-size: $font-size-2;
    line-height: $font-height-2;
  }
}

.recipient-payment-billing-info {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;

  .recipient-payment-billing-info-ce-info {
    flex: 3;
    padding: $layout-m $layout-m;
    overflow-x: auto;
    overflow-y: auto;

    .ce-info-header {
      margin-bottom: $spacing-m;
    }

    .basic-table-wrapper.receipient-payment-billing-info {
      flex: 1;
      width: calc(1200px + 1rem) !important;

      .title-row {
        justify-content: space-between;
        padding-bottom: 40px;
      }

      .basic-table {
        thead {
          th {
            font-weight: 600;
            font-size: $font-size-1;
            line-height: $font-height-1;
            color: $gray-darker;
            white-space: nowrap;
            padding-top: $spacing-none
          }
        }

        tr:hover {
          background-color: #F2F2F2 !important;

          td.edit-cell .edit-cell-button {
            display: inline !important;
            align-content: center;
            margin-right: 0px;
          }
        }

        td.addressCell {
          padding: 0;
        }

        .accordionIcon {
          cursor: pointer;
        }

        td {
          font-family: Averta;
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-1;
          line-height: 45px;
          color: $gray-darker;
          border-bottom: 1px solid #dcdfe6;

          &.idCode {
            white-space: nowrap;
          }

          &.associatedPharmaciesCount {
            text-align-last: center;
          }

          &.edit-cell {
            min-width: 175px;

            .edit-cell-button {
              display: none !important;
            }

            a:hover {
              color: $gray-darker;
            }
          }
        }
      }

      .ce-summary {
        cursor: pointer;
      }

      .ce-info {
        display: flex;
        flex-direction: column;
        background: $bg-gray;
        width: 100%;
        height: 135px;

        .ui.divider {
          border: 0;
        }
      }

      .ce-info-address {
        padding-top: 19px;
        font-family: Averta;
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-1;
        line-height: $font-height-1;
        color: $gray-darker;

        .addressLabel {
          padding-top: 8px;
        }

        .addressTitle {
          font-weight: 600;
        }

        .ce-info-street-address {
          flex: 2;
          padding-left: 36px;
        }

        .ce-info-billing-address {
          flex: 4;
          padding-left: 36px;
        }
      }
    }
  }
}

.retry-payments {
  margin-left: 16px;
  font-size: 12px !important; // override app button important
  width: 119px;
}

.microdeposit-modal-container {
  .modal-close-x-icon {
    cursor: pointer;
    padding: 0;

    svg {
      float: right;
    }
  }

  .microdeposit-modal-header {
    padding-top: 0;
  }

  .microdeposit-modal-message {
    text-align: left;
    padding-top: 18px;
  }

  .microdeposit-btn {
    margin-top: 30px;
    margin-left: auto;
    text-emphasis-style: none;
    text-decoration-skip-ink: none;
  }
}

.link-button {
  padding: 0 !important;
  font-size: 12px !important;
  font-weight: normal !important;
}
