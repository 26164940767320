@import "../../../Design/tokens";
@import "../../../Design/classes";

.standard-modal {
  padding: 32px;

  .modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .standard-modal-header {
      @include header-sans-reg-01();
      font-weight: 600;
    }
  }

  .modal-body {
    padding-top: 16px;

    .body-text {
      @include body-reg-02();
    }
  }

  .modal-footer {
    padding-top: 45px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .button-dark {
      margin-left: 16px;
    }
  }
}