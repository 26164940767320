@import '../../../../Design/tokens';
@import '../../../../Design/classes';

.secondary-drawer {
  .secondary-drawer-header {
    height: 100px;
    min-height: 100px;
    margin: 8px 16px 8px 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-shrink: 0;

    .header-text {
      display: flex;
      justify-content: space-between;

      .value {
        margin-bottom: 4px;
        @include header-sans-semi-01;
      }
    }

    .sub-header {
      @include body-reg-01;
      color: $gray-medium;
      text-transform: uppercase;
    }
  }
}
