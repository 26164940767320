@import '../../Design/tokens';


.empty-transactions-no-records {
    width: 40%;
    text-align: center;
    margin: 96px auto;

  .empty-transactions-sub-msg {
    white-space: pre-wrap;
    margin-top: 25px;
    width: 100%;
  }

}
