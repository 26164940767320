@import './../Design/tokens';

.page-header {
  height: 70px;
  min-height: 70px;
  border-bottom: 1px solid $gray-lighter-border;
  display: flex;
  flex-direction: row;
  align-items: center;

  .page-header-text-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .page-header-icon {
      margin-right: $spacing-s;
    }

    .page-header-text {
      font-size: $font-size-4;
      display: inline;
      letter-spacing: 0.25px;
      margin: 0;
  
      &.editing {
        color: $blue-dark;
        padding-right: 5px;
      }
    }
  }

  &.no-border {
    border-bottom: none !important;
  }

  button {
    background: transparent;
    border: none;
    cursor: pointer;
  }
}
