.edit-history {
  padding: 12px 28px;

  .basic-table-wrapper {
    width: 100%;
    overflow-y: hidden;
    overflow-x: scroll !important;
    display: block;

    .basic-table {
      thead {
        th {
          @include body-semi-01;
          color: $gray-darker;
          font-weight: 600;
          white-space: nowrap;
        }
      }

      td {
        @include body-reg-01;
        font-style: normal;
        line-height: 45px;
        color: $gray-darker;
        border-bottom: 1px solid #dcdfe6;
        white-space: nowrap;
      }
    }
  }
}

.edit-history-download-csv-button {
  border: none;
  background: $primary;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  white-space: nowrap;
  color: $white;
  width: 156px;
  height: 36px;
  padding: 8px 16px 8px 16px;
  display: flex;
  position: absolute;
  right: 60px;
  top: 100px;
  cursor: pointer;

  svg {
    margin-right: 8px;
  }

  .text {
    font-family: $font-family-sans-reg;
    margin-top: 2px;
  }

  &:hover {
    background: $gray-dark;

    &:active {
      background: $gray-pressed;
    }
  }
}
