@import '../../Design/tokens';
@import '../../Design/classes';

.onboarding-layout-root {
  display: flex;
  height: 100%;
  overflow: auto;
}

.onboarding-layout-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 900px;
  width: 1200px;
  min-width: 1200px;
  margin: auto;
  overflow: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar; // fixing overflow in IE11
}

.onboarding-layout-step-name-container {
  display: flex;
  width: 306px;
  height: 820px;
  margin-right: 134px;
  justify-content: center;
  align-items: center;

  .step {
    width: 130px;
    font-family: $font-family-sans-reg;
    font-weight: 600;
    font-size: $font-size-2;
    line-height: 20px;
    color: $gray-light;
    text-align: center;

    &.active {
      color: $primary;
    }

    .step-name {
      height: 40px;
    }

    .step-divider {
      height: 35px;
      width: 1px;
      border-left: 1.67332px dashed $gray-lighter-border;
      margin: 24px auto;
    }
  }

  .step:last-child {

    // hide the last divider
    .step-divider {
      display: none;
    }
  }
}

.onboarding-layout-content-container {
  width: 682px;
  height: 820px;
  box-shadow: $box-shadow-reg;
  background: $bg-gray;
  display: flex;
  flex-direction: column;

  .support-link {
    text-decoration: underline;
  }
}
