@import "../../Design/tokens.scss";

// NOTE: the order of these styles DO matter

// Will edit everything selected including everything between a range of dates
.CalendarDay__selected_span {
  background: $blue-medium; //background
  color: white; //text
  border: 1px solid #e4e7e7; //default styles include a border

  &:hover {
    background: $blue-medium-light;
    border: 1px solid #e4e7e7; //default styles include a border
  }
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  background: $blue-dark;
  border: 1px solid #e4e7e7; //default styles include a border
  color: white;

  &:hover {
    background: $blue-medium-light;
    border: 1px solid #e4e7e7; //default styles include a border
  }
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__default:hover,
.CalendarDay__selected:hover {
  background: $blue-light;
  color: $gray-darker;
  border: 1px solid $blue-light; //default styles include a border
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: $blue-medium;
  color: white;
  border: 1px solid #e4e7e7; //default styles include a border
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid $gray-darker;
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover:before {
  border-right: 33px solid $gray-medium;
}

.DayPickerKeyboardShortcuts_list {
  display: flex;
  flex-direction: column;
}

.KeyboardShortcutRow {
  display: flex;
}

.KeyboardShortcutRow_keyContainer {
  // display: inline-block;
  display: flex;
  flex-direction: row;
}

.KeyboardShortcutRow_key {
  padding-right: 16px;
  color: $blue-dark;
  width: 100px;
}

.react-dates-arrow {
  padding: 8px 4px 4px 4px;
}

.DayPicker_weekHeader {
  top: 58px;
}

.SingleDatePickerInput_1 {
  .DateInput {
    width: 300px;
  }
}
