@import '../../Design/tokens';

.submit-menu {
  position: absolute;
  top: 93px;
  right: -25px;
  letter-spacing: 0.25px;
  z-index: 3;

  .ui.dropdown.button {
    background: $blue-darker !important;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1) !important;
    border-radius: 10px;
    padding: 8px 16px 8px 16px !important;
    min-width: 136px;
    max-height: 36px;
    


    &:hover {
    background: $blue-hover !important;
    }
    &:active {
      background: $blue-pressed  !important;
      }

    &.disabled {
      background: $gray-light !important;
      opacity: 1 !important; // override semantic .45
      box-shadow: none !important;

      &.disabled>.text {
        color: $gray-medium-light;
      }

      &.disabled>.icon {
        color: $gray-medium-light;
      }
    }
  }

  .ui.dropdown .menu {
    top: -24px;
    left: -260px;
    border-radius: 10px !important;
    padding-bottom: 14px;
  }

  .ui.dropdown>.text {
    color: white;
    margin-right: 8px;
  }

  .ui.dropdown>.icon {
    color: white;
    opacity: 1;
  }

  .menu-item {
    padding: 10px 22px;
    border-bottom: 1px solid $gray-lighter-border;
    min-width: 410px;
    cursor: pointer;

    .title {
      color: $gray-darker;
      padding-bottom: 4px;
    }

    .text {
      font-weight: normal;
      color: $primary-medium;
    }
  }

  .menu-item:hover {
    background: $cobalt-extralight;
  }

  .menu-header {
    padding: 22px;
    border-bottom: 1px solid $gray-lighter-border;
    color: $gray-darker;
  }
}