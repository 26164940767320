@import './../Design/tokens';


.notification-center {

  .notification-center-content {
    padding-top: 3rem;
    position: relative;
    max-width: 700px;
    letter-spacing: 0.25px;


    .notification-center-tabs {
      .ui.secondary.pointing.menu .active.item {
        color: $gray-darker;
        border-color: $gray-darker;
      }
    }

    .notification-center-settings-link {
      position: absolute;
      right: 0;
      top: 3.5rem;
    }

    .notification-center-message-container {
      position: relative;
      height: 100px;
    }

    .notification-center-error {
      color: red;
      margin: 5rem auto;
    }
  }
}

.notification-item {
  padding: 1rem;
  border-bottom: 1px solid #D8D8D8;

  &.acknowledged,
  &.acknowledged .icon {
    color: #999999 !important;
  }

  .notification-item-details {
    padding: 0 1rem;

    .notification-item-details-heading {
      text-transform: uppercase;
    }

    .notification-item-details-text {
      padding: 8px 0;
    }

    .notification-item-details-elapsed {
      color: #999999;
    }
  }

  .notification-item-icon {
    color: #999999;
  }

  .notification-item-icon,
  .notification-item-action-icon {
    font-size: 1.5em;
  }
}