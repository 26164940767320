@import '../../Design/tokens';

.file-upload {
  position: fixed;
  top: 0;
  background: $white;
  height: 100%;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: hidden;
  flex-direction: column;
  letter-spacing: 0.25px;

  /* workaround as transition portal set display to block */
  &.visible.transition {
    display: flex !important;
  }

  .file-upload-content {
    overflow-y: hidden;

    .file-upload-header {
      height: 81px;
      padding: 0 1rem 0 3rem;
      min-height: 81px;
    }

    .file-upload-footer {
      height: 81px;
      padding: 0 2rem 0 3rem;
      background: $white;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      min-height: 81px;

      .upload-another {
        margin-right: 1rem;
      }

      .submit-upload.ui.disabled.button {
        opacity: 1 !important;
        background: $gray-lighter-border !important;
        color: #999999;
      }
    }

    .file-upload-body {
      margin-top: 1rem;
      overflow-y: auto;


      .file-upload-step {
        flex: 1 0;
        border-right: 1px solid $primary-light;
        padding-left: 3rem;
      }

      .file-upload-details {
        flex: 3 0;

        .file-upload-progress.ui.progress {
          margin: 2rem 0px;
          border-radius: 10px;
          background-color: rgba(1, 161, 153, 0.1);

          .bar {
            min-width: 0px;
            border-radius: 10px;
            background-color: $evergreen-medium !important;
          }
        }

        .processing-error-notification {
          background: $red-light;
          padding: 1rem;
          color: $red-darker;
          margin: 0px 28px 28px;
        }

        .file-upload-processing {
          margin: 28px;
          text-align: center;

          .file-name {
            margin-right: 2rem;
          }

          .file-size {
            margin-right: 8px;
          }

          &.has-error {
            color: $primary-medium;

            .ui.progress .bar {
              background-color: $primary-light !important;
            }
          }
        }

        .file-upload-success {
          margin: 0px 28px 28px 28px;
          text-align: center;

          .go-to-link {
            .icon {
              color: $primary-light;
            }

            &:hover {
              .icon {
                color: inherit;
              }
            }
          }

          .note {
            margin-top: 20px;
            color: $primary-medium;
          }
        }


        .file-upload-some-errors {
          margin-left: auto;
          margin-right: auto;
          max-width: 900px;

          .error-msgs-container {
            width: 92%;
            max-width: 800px;
            min-width: 600px;
            border: 1px solid $red-darker;
            margin-left: auto;
            margin-right: auto;
            margin-top: 47px;
            margin-bottom: 48px;

            .message-header {
              height: 72px;
              background: $red-light;

              i.warning.sign.icon {
                color: $red-darker;
                margin-top: 23px;
                margin-left: 7px;
              }

              div.delta {
                padding: 16px 11px 11px 11px;
                color: $red-darker;
              }

            }

          }

          .file-size-errors {
            margin-left: 16px;
            color: $primary-medium;
          }

          .validation-container {
            margin-top: 16px;
            margin-bottom: 16px;
            max-height: 383px;
            overflow-y: auto;

            .validation-msg-container {
              color: $red-darker;

              &.invalid {
                margin-left: 24px;
                margin-top: 16px;
                margin-bottom: 16px;
              }

              &.row-error {
                display: flex;
                flex-direction: row;
                margin-left: 24px;
                padding-bottom: 8px;
              }

              div.row-data {
                min-width: 69px;
                width: 69px;
              }

              .validation-results {
                color: $red-darker;
                margin-left: 16px;
              }
            }
          }

          .error-message {
            color: $red-darker;
            margin-left: auto;
            margin-right: auto;
            margin-top: 48px;
            text-align: center;
          }

          .file-name-errors-row {
            margin-left: auto;
            margin-right: auto;
            margin-top: 3%;

            .file-name-errors {
              color: $primary-medium;
            }
          }

          .file-upload-progress.ui.progress {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 48px;

            .bar {
              background-color: $primary-medium !important;
            }
          }
        }
      }
    }
  }

  .stepper {
    color: $primary-light;

    &.active {
      color: black;
    }

    .divider-container {
      width: 24px;

      .divider {
        border: 2px solid $primary-light;
        height: 42px;
        margin: 4px 0px;
        border-radius: 5px;
      }
    }

    &:last-child {
      .divider-container {
        display: none;
      }
    }
  }
}

.drop-zone-container {
  margin: 0px 28px 28px 28px;

  .drop-zone {
    height: 300px;
    background: $primary-extra-light;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 2px dashed $primary-light;
    border-radius: 10px;

    &.highlight {
      background-color: rgba(155, 193, 230, .8);
    }

    &.has-error {
      border: 2px solid $red-darker;
    }

    .file-input {
      display: none;
    }

    .empty-file {
      text-align: center;

      .or-divider {
        padding: 14px 0;
      }
    }
    
    .file-info {
      padding: 8px 4px 8px 8px;
      background-color: $white;
      text-align: center;
    }

    .file-name {
      margin-right: 2rem;
    }

    .file-size {
      margin-right: 8px;
    }
  }

  .instructions {
    margin-top: 3rem;

    .download {
      padding: 17px 0 0 0;
      color: $gray-darker;
    }

    &.has-error {
      color: $red-darker;
      margin-top: 1rem;
    }
  }
}